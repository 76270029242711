import { Modal } from 'antd';

const StoreUpdatedSuccssModal = ({ show, onDismiss }) => {
	return (
		<>
			<Modal
				open={show}
				centered
				header={null}
				footer={null}
				onCancel={onDismiss}
				closable={false}
				width={300}
			>
				<div
					style={{
						display: 'flex',
						justifyContent: 'flex-end',
					}}
				>
					<svg
						onClick={onDismiss}
						xmlns="http://www.w3.org/2000/svg"
						width="24"
						height="24"
						viewBox="0 0 24 24"
						fill="none"
					>
						<path
							d="M12 0C18.6274 0 24 5.37258 24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12C0 5.37258 5.37258 0 12 0ZM15.435 6.435L11.9955 9.8745L8.55632 6.435L6.435 8.55632L9.8745 11.9955L6.435 15.435L8.55632 17.5563L11.9955 14.1165L15.435 17.5563L17.5563 15.435L14.1165 11.9955L17.5563 8.55632L15.435 6.435Z"
							fill="#30E700"
						/>
					</svg>
				</div>
				<div className="text-center">
					<svg
						xmlns="http://www.w3.org/2000/svg"
						width="60"
						height="60"
						viewBox="0 0 60 60"
						fill="none"
					>
						<path
							d="M24.25 28.2499C23.25 27.2499 21.75 27.2499 20.75 28.2499C19.75 29.2499 19.75 30.7499 20.75 31.7499L28.25 39.2499C28.75 39.7499 29.25 39.9999 30 39.9999C30.75 39.9999 31.25 39.7499 31.75 39.2499L49.25 19.2499C50 17.9999 50 16.4999 48.75 15.7499C47.75 14.9999 46.25 14.9999 45.5 15.9999L30 33.7499L24.25 28.2499Z"
							fill="#30E700"
						/>
						<path
							d="M52.5 27.5C51 27.5 50 28.5 50 30C50 41 41 50 30 50C19 50 10 41 10 30C10 24.75 12 19.75 15.75 16C19.5 12 24.5 10 30 10C31.5 10 33.25 10.25 34.75 10.5C36 11 37.5 10.25 38 8.75C38.5 7.25 37.5 6.25 36.25 5.75H36C34 5.25 32 5 30 5C16.25 5 5 16.25 5 30.25C5 36.75 7.75 43.25 12.25 47.75C17 52.5 23.25 55 29.75 55C43.5 55 54.75 43.75 54.75 30C55 28.5 53.75 27.5 52.5 27.5Z"
							fill="#30E700"
						/>
					</svg>

					<div className="submit-receipt-success-header"
						style={{
							fontFamily: "Hero New",
							fontSize: "20px",
							fontWeight: 700,
							lineHeight: "normal",
						}}
					>Success</div>
					<div className="submit-pending-modal-font mt-1">
						<p className="text-center"
							style={{
								fontFamily: "Hero New",
							fontSize: "16px",
							fontWeight: 400,
							lineHeight: "normal",
						}}
						>
							The store has been changed
							<br/>successfully
						</p>
					</div>
				</div>
			</Modal>
		</>
	);
};

export default StoreUpdatedSuccssModal;
