import "../Retailer/index.css";
import { useState, useEffect } from "react";
import { Button } from "antd";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import OtpInput from "react18-input-otp";
import haleon from "../../images/Retailer/onboarding.png";
import axiosClient from "../../utils/axiosClient";
import { set } from "date-fns";

const inputStyle = {
  width: "45px",
  height: "45px",
  fontSize: "15px",
  fontStyle: "bold",
  borderRadius: "10px",
  background: "white",
};

const containerStyle = {
  textAlign: "center",
  margin: "auto",
  justifyContent: "center",
  marginBottom: "5%",
};

const OpVerify = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [otp, setOtp] = useState("");
  const [error, setError] = useState("");
  const [timer, setTimer] = useState(60);

  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (timer > 0)
      setTimeout(() => {
        setTimer(timer - 1);
      }, 1000);
  }, [timer]);

  const checkOtp = async () => {
    setError();
    setIsLoading(true)
    try {
      const verifyOtp = {
        otp,
        operatorCode: location.state.operatorCode,
        "tokenType": "PHONE"
      };
      const res = await axiosClient.post("/operator/auth/login-otp", verifyOtp);
      if (res.data.operateType=== "retailer") {
        localStorage.setItem("retailer-token", res.data.token);
        navigate("/retailer/home");
      } else if (res.data.operateType=== "dsr") {
        localStorage.setItem("dsr-token", res.data.token);
        navigate("/salesRep/home");
      }
    } catch (error) {
      console.error(error);
      setError(error?.response?.data?.message || 'Something went wrong');
    } finally {
        setIsLoading(false)
      }
  };

  const resendOtp = async () => {
    setOtp("");
    if (timer <= 0) {
      setTimer(60);
    }
    setError();
    setIsLoading(true)
    try {
      const res = await axiosClient.post('/operator/auth/request-otp', { 
        operatorCode: location.state.operatorCode,
				"tokenType": "PHONE"
			 });
    } catch (error) {
      setError(error?.response?.data?.message || 'Something went wrong');
    } finally {
      setIsLoading(false)
    }
  };

  return (
    <div className="ret-login-home" style={{
      width: '100%',
      opacity: isLoading ? 0.5 : 1,
      pointerEvents: isLoading ? 'none' : 'auto'
    }}>
      <div style={{width:'80%', margin:'auto'}}>
      <div className="ret-login-title">Verification Code</div>
      <div className="ret-login-subtitle">
        Please type verification code sent
        <br />
        to +6{location.state.phoneNo}
      </div>
      <div className="ret-login-input-container">
        <OtpInput
          value={otp}
          numInputs={4}
          onChange={(e) => setOtp(e)}
          isInputNum={true}
          separator={<span> &nbsp;&nbsp;&nbsp;&nbsp; </span>}
          containerStyle={containerStyle}
          inputStyle={inputStyle}
        />
      </div>
      <div style={{ textAlign: "center", fontSize: "22px" }}>{timer}s</div>
      <div
        style={{
          textAlign: "center",
          fontSize: "16px",
          cursor: "pointer",
          marginTop: " 5px",
        }}
      >
        Didn’t get OTP?{" "}
        <b
          style={{
            textDecoration: "underline",
            opacity: timer > 0 ? "0.5" : "1",
          }}
          onClick={timer === 0 ? resendOtp : null}
        >
          Resend OTP
        </b>
      </div>
      <div style={{
        color: "red", marginTop: "35%", textAlign: "center", widows: '100%',
        display: 'flex',
      }}>
        {error}
        <Button
          onClick={checkOtp}
					htmlType="button"
					type="primary"
					style={{
            width: '100%',
							height: '45px',
							borderRadius: '2px',
							backgroundColor: '#000',
							color: 'white',
							fontSize: '16px',
							// fontFamily: 'Gotham',
							fontWeight: '700',
							marginBottom: '30px',
					}}
				>
					Submit
				</Button>
        </div>
        </div>
    </div>
  );
};

export default OpVerify;
