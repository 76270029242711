import { SET_DSR_PROFILE, SET_ERROR, SET_LOADING } from './dsrActionTypes';

const initialState = {
	dsrProfile: null,
	isLoading: false,
	error: null,
};

const dsrReducer = (state = initialState, action) => {
	switch (action.type) {
		case SET_LOADING:
			return {
				...state,
				isLoading: action.payload,
			};
		case SET_DSR_PROFILE:
			return {
				...state,
				isLoading: false,
				dsrProfile: action.payload,
			};
		case SET_ERROR:
			return {
				...state,
				isLoading: false,
				error: action.payload,
			};
		default:
			return state;
	}
};

export default dsrReducer;
