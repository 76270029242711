import "../Retailer/index.css";
import logo from "../../images/Retailer/onboarding.svg";
import { useHistory, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";

const OpLoading = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const timer = setTimeout(() => {
      navigate("/operator/login" + window.location.search);
    }, 2000);

    return () => {
      clearTimeout(timer);
    }
  }, [navigate]);

  return (
    <div>
      <div className="loading-retailer">
        <img src={logo} alt="" className="w-100"/>
      </div>
    </div>
  );
};

export default OpLoading;
