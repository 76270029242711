import "./App.css";
import { RETAILER, SALE_REP } from "./enums";
import { Navigate, Route, Routes } from "react-router-dom";
import React, { useEffect, useState } from "react";
import AuthRedirect from "./utilities/AuthRedirect";
import OperatorLoadingPage from "./view/Operator/OpLoading";
import OperatorLoginPage from "./view/Operator/OpLogin";
import OperatorVerifyPage from "./view/Operator/OpVerify";
import RetailerHomePage from "./view/Retailer/RetailerHome";
import RetailerQRCodePage from "./view/Retailer/RetailerQRCode";
import RetailerStatementPage from "./view/Retailer/QuarterlyStatement";
import SalesRepHomePage from "./view/SalesRep/SalesRepHome";
import SalesRepInstructionPage from "./view/SalesRep/SalesRepInstruction";
import SalesRepScanQRPage from "./view/SalesRep/SalesRepScanQR";
import SalesRepStoreDetailsPage from "./view/SalesRep/SalesRepStoreDetails";
import SalesRepStoreSummaryPage from "./view/SalesRep/SalesRepSummary";
import SalesRepStoreUploadPage from "./view/SalesRep/SalesRepUpload";
import { clearError } from "./reducer/userDetailsReducer";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import SalesRepRegisterStore from "./view/SalesRep/SalesRepRegisterStore/SalesRepRegisterStore";
import SalesRepLayout from "./components/SalesRepLayout/SalesRepLayout";
import SalesRepEditStore from "./view/SalesRep/SalesRepEditStore/SalesRepEditStore";
import StoreAccountRanking from "./view/Retailer/StoreAccountRanking";
import DraggableWhatsapp from "./components/DraggableWhatsapp";
const packageJson = require("../package.json");

function App() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  // const navigate = useNavigate();
  const location = window.location.pathname;

  useEffect(() => {
    dispatch(clearError());
  }, [dispatch, location]);

  const isDev = process.env.REACT_APP_ENV === "dev";
  const isShopper = !window?.location?.pathname?.includes(
    "admin" || "operator" || "retailer"
  );


  return (
    <div>
      {isDev && (
        <p
          style={{
            textAlign: "center",
            color: "orange",
            background: "black",
            position: "fixed",
            left: "50%",
            zIndex: 10000,
            padding: "2px 4px",
            pointerEvents: "none",
            cursor: "default",
          }}
        >
          v-{packageJson.version}
        </p>
      )}
      {isShopper ? (
        <div className="App">

          <Routes>
            {/* Operator */}
            <Route exact path="/" element={<OperatorLoginPage t={t} />} />
            <Route
              path="/operator/loading"
              element={<OperatorLoadingPage t={t} />}
            />
            <Route
              path="/operator/login"
              element={<OperatorLoginPage t={t} />}
            />
            <Route
              path="/operator/verify-otp"
              element={
                <SalesRepLayout t={t}>
                  <OperatorVerifyPage t={t} />
                </SalesRepLayout>
              }
            />

            {/* Retailer */}
            <Route
              path="/retailer/home"
              element={
                <AuthRedirect role={RETAILER}>
                  <RetailerHomePage t={t} />
                </AuthRedirect>
              }
            />
            <Route
              path="/retailer/view/statement"
              element={
                <AuthRedirect role={RETAILER}>
                  <RetailerStatementPage t={t} />
                </AuthRedirect>
              }
            />

            <Route
              path="/retailer/ranking-overview"
              element={
                <AuthRedirect role={RETAILER}>
                  <StoreAccountRanking />
                </AuthRedirect>
              }
            />

            {/* <Route
              path="/retailer/showQR"
              element={
                <AuthRedirect role={RETAILER}>
                  <RetailerQRCodePage t={t} />
                </AuthRedirect>
              }
            /> */}
            {/* <Route path="*" element={<AuthRedirect /> role={') <navigate to="/login" />} *}></AuthRedirect>}

						{/* SalesRep */}
            <Route
              path="/salesRep/home"
              element={
                <AuthRedirect role={SALE_REP}>
                  <SalesRepLayout t={t} showLogoutBtn>
                    <SalesRepHomePage t={t} />
                  </SalesRepLayout>
                </AuthRedirect>
              }
            />
            <Route
              path="/salesRep/stores/register"
              element={
                <AuthRedirect role={SALE_REP}>
                  <SalesRepLayout t={t} showLogoutBtn>
                    <SalesRepRegisterStore t={t} />
                  </SalesRepLayout>
                </AuthRedirect>
              }
            />
            <Route
              path="/salesRep/stores/edit"
              element={
                <AuthRedirect role={SALE_REP}>
                  <SalesRepLayout t={t} showLogoutBtn>
                    <SalesRepEditStore t={t} />
                  </SalesRepLayout>
                </AuthRedirect>
              }
            />
            <Route
              path="/salesRep/instruction"
              element={
                <AuthRedirect role={SALE_REP}>
                  <SalesRepInstructionPage t={t} />
                </AuthRedirect>
              }
            />
            <Route
              path="/salesRep/scanQR"
              element={
                <AuthRedirect role={SALE_REP}>
                  <SalesRepScanQRPage t={t} />
                </AuthRedirect>
              }
            />
            <Route
              path="/salesRep/storeDetails"
              element={
                <AuthRedirect role={SALE_REP}>
                  <SalesRepStoreDetailsPage t={t} />
                </AuthRedirect>
              }
            />
            <Route
              path="/salesRep/upload"
              element={
                <AuthRedirect role={SALE_REP}>
                  <SalesRepStoreUploadPage t={t} />
                </AuthRedirect>
              }
            />
            <Route
              path="/salesRep/summary"
              element={
                <AuthRedirect role={SALE_REP}>
                  <SalesRepStoreSummaryPage t={t} />
                </AuthRedirect>
              }
            />

            {/* if any unknow route, redirect operator login page */}
            <Route
              path="*"
              element={<Navigate to="/operator/login" replace />}
            />
          </Routes>
        </div>
      ) : null}
    </div>
  );
}

export default App;
