import React, { useEffect, useState } from "react";
import backArrow from "../../images/backArrow.png";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Button, Table } from "antd";
import axiosClientEncryption from "../../utils/axiosClientEncrytion";
import downloadIcon from "../../images/Vector.png";
import { config, getCurrentConfigPhase } from "../../utilities/config";
import { isWithinInterval } from "date-fns";


const QuarterlyStatement = () => {
  const currentPhase = getCurrentConfigPhase(new Date(), config);

  const navigate = useNavigate();
  const moment = require("moment");
  const [retailerInfo, setRetailerInfo] = useState([]);
  const [totalEntitlement, setTotalEntitlement] = useState([]);
  const [retailerRanking, setRetailerRanking] = useState([]);
  const [leaderboardRanking, setLeaderboardRanking] = useState([]);
  const [totalEntitlementAmount, setTotalEntitlementAmount] = useState([]);
  const [userDetail, setUserDetail] = useState();
  const [channel, setChannel] = useState();
  const [indexLeaderboard, setIndexLeaderboard] = useState();

  const [showQuarterlyEntitlement, setShowQuarterlyEntitlement] =
    useState(false);

  useEffect(() => {
    const fetchData = async () => {
      /* The `showQuarterlyEntitlement` state variable is used to conditionally render different
      content in the `QuarterlyStatement` component. When `showQuarterlyEntitlement` is `true`,
      it displays the quarterly statement information including total entitlement, statement ID,
      date, and a table of earned amounts. When `showQuarterlyEntitlement` is `false`, it
      displays a message indicating that the statement is not available at the moment and prompts
      the user to check back later. This state variable controls the visibility of the quarterly
      statement content based on its value. */
      try {
        const profileRes = await axiosClientEncryption.get("/retailer/profile");
        setRetailerInfo(profileRes?.data?.retailerIds);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    const fetchCurrentCampaign = async () => {
      try {
        if (!currentPhase) {
          console.error("Current phase config not found");
        }
        const campaign = await axiosClientEncryption.get(
          "/campaigns/" + currentPhase.campaignId
        );
        const entitlementReportPeriod =
          campaign?.data?.config?.entitlementReportPeriod;
        if (
          isWithinInterval(new Date(), {
            start: new Date(entitlementReportPeriod.startAt),
            end: new Date(entitlementReportPeriod.endAt),
          })
        ) {
          setShowQuarterlyEntitlement(true);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchCurrentCampaign();
    fetchData();
  }, []);

  useEffect(() => {
    const fetchEntitlement = async () => {
      try {
        if (!currentPhase) {
          throw new Error("Current phase config not found");
        }

        const campaignId = currentPhase.campaignId;
        const prevCampaignId = currentPhase.prevCampaignId;

        const checkTotalEntitlementRes = await axiosClientEncryption.get(
          `/haleon/retailer/quarterly-entitlement`,
          {
            params: {
              retailerId: retailerInfo,
              campaignId: campaignId,
              prevCampaignId: prevCampaignId,
            },
          }
        );

        let retailerId = localStorage.getItem("retailer-id");
        let channel = localStorage.getItem("channel");
        setChannel(channel);

        try {
          const checkRankEntitlementRes = await axiosClientEncryption.get(
            `/haleon/retailer/ranks/recruitment-users`,
            {
              params: {
                retailerId: retailerId,
                campaignId: prevCampaignId,
                returnAllRetailers: false,
              },
            }
          );

          if (checkRankEntitlementRes?.data?.data?.retailersRank) {
              setRetailerRanking(checkRankEntitlementRes?.data?.data?.retailersRank);
          }
        } catch (error) {
          console.error(
            "Error fetching rank entitlement:",
            error.response ? error.response.data : error.message
          );
        }

        try {

          const prevCampaign = await axiosClientEncryption.get(
            "/campaigns/" + currentPhase.prevCampaignId
          );

          const checkRecruitedUsersRes = await axiosClientEncryption.get(
            `/haleon/retailer/recruited-users`,
            {
              params: {
                retailerId: retailerId,
                returnAllRetailers: false,
                createdAt: prevCampaign?.data?.createdAt,
                createdAtEnd: prevCampaign?.data?.endAt,

              },
            }
          );
           
        } catch (error) {
          console.error(
            "Error fetching Recruited Users:",
            error.response ? error.response.data : error.message
          );
        }

        try {
          const profileRes = await axiosClientEncryption.get(
            "/retailer/profile"
          );

          setUserDetail(profileRes?.data?.name);
        } catch (error) {
          console.error("Error fetching data:", error);
        }

        if (checkTotalEntitlementRes?.data?.data) {
          setTotalEntitlement(checkTotalEntitlementRes?.data?.data);
        }

       
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchEntitlement();
  }, []);

  useEffect(() => {
    // Filter recruitedUsers length greater than 30
    const filteredEntitlement = totalEntitlement.filter(item => item.recruitedUsers.length > 30);
    setIndexLeaderboard(filteredEntitlement.length);

    // Sort in descending order
    const sortedFilteredEntitlement = filteredEntitlement.sort((a, b) => b.totalEntitlementAmount - a.totalEntitlementAmount);

    // Find the matching IDs in retailerRanking array
    const matches = sortedFilteredEntitlement.map(entitlement => {
      return retailerRanking.find(retailer => retailer.id === entitlement.id);
    }).filter(match => match !== undefined);

    // Sum up the totalEntitlement
    const total = totalEntitlement.reduce((acc, item) => acc + item.totalEntitlementAmount, 0);
    setTotalEntitlementAmount(total);

    

    if (matches.length > 0) {
      setLeaderboardRanking(matches);
    }
    
  }, [totalEntitlement]);

  // statement total entitlement
  const renderStatementInfo = () => {

    let updatedTotalEntitlementAmount = totalEntitlementAmount + (500*indexLeaderboard);

    const data = totalEntitlement[0];

    return {
      totalEntitlementAmount: updatedTotalEntitlementAmount,
      statementId: data.currentQuarterlyData?.statementId,
    };
  
  };

  const handlePrint = () => {
    window.print();
  };

  const columns = [
    {
    title: 'Rank',
    dataIndex: 'rank',
    key: 'rank',
    className: 'bold-text',
    },
    {
    title: 'Name',
    dataIndex: 'name',
      render: (name) => {
        return <span>TOP OUTLET CHALLENGE: {name}</span>;
      },
    key: 'name',
    className: 'bold-text',
    },
    {
    title: 'Amount Earned',
    dataIndex: 'earned',
      render: () => {
        return <span style={{ color: "#45C925" }}>RM500</span>;
      },
    key: 'earned',
    className: 'bold-text',
    },
  ];

  return (
    <>
      {showQuarterlyEntitlement ? (
        <div className="mt-2 ml-2 mr-2 mt-3" style={{ marginBottom: "30px" }}>
          <div style={{ justifyContent: "left" }}>
            <img
              src={backArrow}
              style={{ width: "20px" }}
              alt=""
              onClick={() => navigate(-1)}
            />
          </div>
          <div className="mt-3">
            <div style={{ display: "flex", justifyContent: "space-evenly" }}>
              <h2 className="text-center" style={{ fontSize: "24px" }}>
                Quarterly Statement
              </h2>
              <img
                src={downloadIcon}
                alt=""
                style={{ width: "5%", objectFit: "contain" }}
              />
            </div>
            <p className="text-center fontSize-13 mt-2">
               {userDetail}
            </p>
            <p className="text-center fontSize-18 mt-2">
              {totalEntitlement[0]?.retailer?.channel}
            </p>
          </div>

          {totalEntitlement && totalEntitlement.length ? (
            <>
              <div style={{ paddingBottom: "10%" }}>
                {/* header body statement */}
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-evenly",
                    textAlign: "center",
                  }}
                  className="mt-4"
                >
                  <div>
                    <p
                      style={{
                        fontWeight: "0",
                        textAlign: "center",
                        color: "#444",
                      }}
                    >
                      Total
                    </p>
                    <span style={{ fontWeight: "700", fontSize: "12px" }}>
                      RM {renderStatementInfo().totalEntitlementAmount}
                    </span>
                  </div>
                  <div>
                    <span style={{ fontWeight: "700", fontSize: "12px" }}>
                      {renderStatementInfo().statementId}
                    </span>
                  </div>
                  <div>
                    <p
                      style={{
                        fontWeight: "0",
                        textAlign: "center",
                        color: "#444",
                      }}
                    >
                      Date
                    </p>
                    <span style={{ fontWeight: "700", fontSize: "12px" }}>
                      {moment(new Date()).format("DD MMM YYYY")}
                    </span>
                  </div>
                </div>

                
                {channel === 'IP' ? (
                  <div>
                    {/* table body Leaderboard */}
                    <div className="mt-2" style={{ padding: "0 1rem" }}>
                      <p className="text-center fontSize-13 mt-2">
                        Leaderboard
                      </p>
                      <Table className="custom-header" columns={columns} dataSource={leaderboardRanking} size="small" />
                    </div>
                    {/* table body statement */}
                    <div className="mt-2">
                      <table className="statement-table-form">
                        <thead>
                          <tr>
                            <th>Date</th>
                            <th>Item</th>
                            <th>Amount Earned</th>
                          </tr>
                        </thead>
                        <tbody>
                          {totalEntitlement.map((entitlementInfo, idx) => (
                            entitlementInfo.recruitedUsers.map((user, idx) =>
                              <tr key={idx} style={{ border: "3px" }}>
                                <td>
                                  {moment(user?.dateRange?.startFrom).format(
                                    "DD-MM-YY"
                                  )}
                                  /
                                  {moment(user?.dateRange?.endAt).format(
                                    "DD-MM-YY"
                                  )}
                                </td>
                                <td>New User Registration</td>
                                <td style={{ color: "#45C925" }}>+ RM 5</td>
                              </tr>
                            )
                          ))}
                        </tbody>
                      </table>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                        className="ml-2 mr-2 mt-1"
                      >
                        <span style={{ textAlign: "left" }}>Total</span>
                        <span style={{ textAlign: "right", fontWeight: "900" }}>
                          RM {renderStatementInfo().totalEntitlementAmount}
                        </span>
                      </div>
                    </div>
                  </div>
                ) : (
                  <>
                    {/* table body Leaderboard */}
                    <div className="mt-2" style={{ padding: "0 1rem" }}>
                      <p className="text-center fontSize-13 mt-2">
                        Leaderboard
                      </p>
                      <Table className="custom-header" columns={columns} dataSource={leaderboardRanking} size="small" />
                    </div>

                    {/* table body statement */}
                    <div className="mt-2">
                      <table className="statement-table-form">
                        <thead>
                          <tr>
                            <th>Date</th>
                            <th>Item</th>
                            <th>Amount Earned</th>
                          </tr>
                        </thead>
                        <tbody>
                          {totalEntitlement.map((entitlementInfo, idx) => (
                            <tr key={idx} style={{ border: "3px" }}>
                              <td>
                                {moment(
                                  entitlementInfo?.dateRange?.startFrom
                                ).format("DD-MM-YY")}
                                /
                                {moment(
                                  entitlementInfo?.dateRange?.endAt
                                ).format("DD-MM-YY")}
                              </td>
                              <td>
                                New User Registration: {entitlementInfo.name}
                              </td>
                              <td style={{ color: "#45C925" }}>
                                + RM {entitlementInfo.totalEntitlementAmount}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                        className="ml-2 mr-2 mt-1"
                      >
                        <span style={{ textAlign: "left" }}>Total</span>
                        <span style={{ textAlign: "right", fontWeight: "900" }}>
                          RM {renderStatementInfo().totalEntitlementAmount}
                        </span>
                      </div>
                    </div>
                  </>
                )}
                <Button
                  className="print-statement-button mt-3 fontSize-18"
                  onClick={handlePrint}

                >
                  Print Statement
                </Button>
              </div>
            </>
          ) : (
            <p className="text-center">Loading...</p>
          )}
        </div>
      ) : (
        <div style={{ position: "relative" }}>
          <div style={{ display: "flex", justifyContent: "left" }}>
            <img
              src={backArrow}
              style={{ width: "20px", paddingLeft: "10%", paddingTop: "10%" }}
              alt=""
              onClick={() => navigate(-1)}
            />
          </div>
          <div
            style={{
              position: "absolute",
              top: "250%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              textAlign: "center",
              width: "100%",
              paddingBottom: "10%",
            }}
          >
            <h3 className="text-center mt-3">
              Statement not available for now
            </h3>
            <h4>Please check back again later.</h4>
          </div>
        </div>
      )}
    </>
  );
};

export default QuarterlyStatement;
