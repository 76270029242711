import { Tabs, message } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useState } from "react";

import { fetchDsrProfile } from "../../../reducer/dsr/dsrActions";
import axiosClient from "../../../utils/axiosClient";
import StoreEditDetailsForm from "./components/StoreEditDetailsForm";
import StoreUpdatedSuccssModal from "./components/StoreUpdatedSuccssModal";
import StoreReUploadReceipt from "./components/StoreReUploadReceipt";

import "./SalesRepEditStore.css";

const EDIT_STORE_TABS = {
	DETAILS: "details",
	UPLOAD_RECEIPT: "upload_receipt",
};

const SalesRepEditStore = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();

	// useselector
	const { dsrProfile, isLoading: dsrIsLoading } = useSelector((state) => state.dsr);
	const dsrRetailers = dsrProfile?.retailers || [];

	const [forceRender, setForceRender] = useState(1);
	const [tab, setTab] = useState(EDIT_STORE_TABS.DETAILS);
	const [isLoading, setIsLoading] = useState(false);

	const [storeDetails, setStoreDetails] = useState();
	const [isStoreUpdated, setIsStoreUpdated] = useState(false);
	const [imageFile, setImageFile] = useState();

	const [errorMessage, setErrorMessage] = useState(null);

	useEffect(() => {
		if (!dsrProfile && !dsrIsLoading) {
			dispatch(fetchDsrProfile());
		}
	}, [dsrProfile, dsrIsLoading, dispatch]);

	const submitUploadReceipt = async () => {
		errorMessage && setErrorMessage(null);

		const storeId = localStorage.getItem("HALEON-PARTNER_STORE_ID");
		if (!storeId) {
			setErrorMessage("Store missing!");
			return;
		}

		setIsLoading(true);
		try {
			if (imageFile) {
				// UPLOAD-RECEIPT
				const data = new FormData();
				data.append("image", imageFile);
				const updatedStore = await axiosClient.patch(`/dsr/retailers/${storeId}/upload-receipt`, data, {
					headers: { "Content-Type": "multipart/form-data" },
				});
			}

			setIsStoreUpdated(true);
			localStorage.removeItem("HALEON-PARTNER_STORE_ID");
		} catch (err) {
			console.error("Error occurred:", err);
			if (err.response && err.response.data && err.response.data.message) {
				setErrorMessage(err.response.data.message);
			} else {
				setErrorMessage("An error occurred while processing data.");
			}
		} finally {
			setIsLoading(false);
		}
	};

	const submitEditStore = async (_storeDetails) => {
		errorMessage && setErrorMessage(null);

		const { id, name } = _storeDetails;
		if (!id || !name) {
			setErrorMessage("Please enter store name!");
			return;
		}

		setIsLoading(true);
		try {
			const { name, id } = _storeDetails;

			const storeData = {
				id,
				name,
			};

			const storeCreateRes = await axiosClient.put("/dsr/retailers", storeData);

			if (!storeCreateRes || !storeCreateRes.data || !storeCreateRes.data.data) {
				message.error("Error on creating store.");
				return;
			}
			localStorage.setItem("HALEON-PARTNER_STORE_ID", storeCreateRes.data.data.id);

			setTab(EDIT_STORE_TABS.UPLOAD_RECEIPT);
			setForceRender((prev) => prev + 1);
		} catch (err) {
			console.error("Error occurred:", err);
			if (err.response && err.response.data && err.response.data.message) {
				setErrorMessage(err.response.data.message);
			} else {
				setErrorMessage("An error occurred while processing data.");
			}
		} finally {
			setIsLoading(false);
		}
	};

	const onFinishDetailForm = (values) => {
		setStoreDetails(values);
		submitEditStore(values);
	};

	const onFinishUploadReceipt = (file) => {
		setImageFile(file);
		submitUploadReceipt();
	};

	return (
		<div
			style={{
				margin: "20px",
				opacity: isLoading ? 0.5 : 1,
				pointerEvents: isLoading ? "none" : "all",
			}}
		>
			<StoreUpdatedSuccssModal
				show={isStoreUpdated}
				onDismiss={() => {
					setIsStoreUpdated(false);
					navigate("/salesRep/home");
				}}
			/>

			<Tabs key={forceRender} defaultActiveKey={tab} animated>
				<Tabs.TabPane tab="Tab 1" key={EDIT_STORE_TABS.DETAILS}>
					<h1
						style={{
							textAlign: "center",
							color: "var(--blk, #1B1A1A)",
							fontFamily: "Hero New",
							fontSize: "25px",
							fontStyle: "normal",
							fontWeight: 700,
							lineHeight: "normal",
						}}
					>
						Edit Store Name
					</h1>
					<StoreEditDetailsForm errorMessage={errorMessage} onBack={() => navigate("/salesRep/home")} retailers={dsrRetailers} onFinish={(values) => onFinishDetailForm(values)} />
				</Tabs.TabPane>
				<Tabs.TabPane tab="Tab 2" disabled key={EDIT_STORE_TABS.UPLOAD_RECEIPT}>
					<h1
						style={{
							color: "var(--blk, #1B1A1A)",
							textAlign: "center",
							fontFamily: "Hero New",
							fontSize: "25px",
							fontStyle: "normal",
							fontWeight: 700,
							lineHeight: "normal",
						}}
					>
						One Last Step...
					</h1>
					<p
						style={{
							textAlign: "center",
						}}
					>
						Upload any receipt of this store for the
						<br /> validation purpose
					</p>

					<StoreReUploadReceipt
						onBack={() => {
							setTab(EDIT_STORE_TABS.DETAILS);
							setForceRender((prev) => prev + 1);
						}}
						onFinish={onFinishUploadReceipt}
						errorMessage={errorMessage}
					/>
				</Tabs.TabPane>
			</Tabs>
		</div>
	);
};

export default SalesRepEditStore;
