import "../Retailer/index.css";
import React, { useState } from "react";
import { Form, Input, Button } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import checkInputFormat from "../../utils/misc/validation";
import haleon from "../../images/Retailer/onboarding.png";
import axiosClient from "../../utils/axiosClient";
import { useEffect } from "react";

const OpLogin = () => {
	const [form] = Form.useForm();
	const [error, setError] = useState("");
	const navigate = useNavigate();

	// find the company id from search params and set it in local storage
	const companyId = new URLSearchParams(useLocation().search).get("companyId");
		
		useEffect(() => {
		if (companyId) {
			localStorage.setItem("x-company-id", companyId);
		}
	}, [companyId]);

	const handleSubmit = async (values) => {
		const { operatorCode, password } = values;
		if (!operatorCode || !password) return setError("Please fill all the fields");

		try {
			const res = await axiosClient.post("/operator/auth/login-password", {
				operatorCode,
				password,
			});

			if (res.data.operateType === "retailer") {
				localStorage.setItem("retailer-token", res.data.token);
				navigate("/retailer/home");
			} else if (res.data.operateType === "dsr") {
				localStorage.setItem("dsr-token", res.data.token);
				navigate("/salesRep/home");
			}
		} catch (error) {
			console.log(error);
			setError(error?.response?.data?.message || "Something went wrong");
		}
	};

	const validator = async (rule, value) => {
		const { result, message } = checkInputFormat({ [rule.field]: value });
		if (!result) {
			return Promise.reject(new Error(message));
		}
	};

	const customizeRequiredMark = (label, { required }) => (
		<>
			{label}
			{required && <span style={{ color: "red" }}>*</span>}
		</>
	);

	return (
		<div className="ret-login-home">
			<div>
				<img src={haleon} alt="" className="ret-login-logo" />
			</div>
			<div
				className="ret-login-title"
				style={{
					fontFamily: "Hero New",
					fontSize: "24px",
					fontStyle: "normal",
					fontWeight: 700,
					lineHeight: "normal",
				}}
			>
				Login
			</div>
			<div className="ret-login-subtitle">
				<p
					className="text-center"
					style={{
						color: "#262626",
						fontFamily: "Gotham",
						fontSize: "16px",
						fontStyle: "normal",
						fontWeight: "400",
						lineHeight: "normal",
					}}
				>
					To log in, please enter your store
					<br /> ID or sales representative ID:
				</p>
			</div>
			<div className="ret-login-input-container">
				<Form
					requiredMark={customizeRequiredMark}
					form={form}
					name="login-form"
					onFinish={handleSubmit}
					style={{
						width: "80%",
						margin: "auto",
					}}
					layout="vertical"
				>
					<Form.Item
						style={{
							color: "#262626",
							fontFamily: "Gotham",
							fontSize: "16px",
							fontStyle: "normal",
							fontWeight: 700,
							lineHeight: "normal",
						}}
						required
						type="text"
						label={"Store/ Sales Rep ID"}
						name="operatorCode"
						rules={[
							{
								validator,
							},
							{
								required: true,
								message: "ID is required",
							},
						]}
					>
						<Input
							style={{
								width: "100%",
							}}
							type="text"
							name="operatorCode"
							className="ret-form-input-login"
							placeholder="Enter ID"
							autoComplete="off"
							required
						/>
					</Form.Item>

					<Form.Item
						style={{
							color: "#262626",
							fontFamily: "Gotham",
							fontSize: "16px",
							fontStyle: "normal",
							fontWeight: 700,
							lineHeight: "normal",
						}}
						required
						type="password"
						label={"Password"}
						name="password"
						rules={[
							{
								validator,
							},
							{
								required: true,
								message: "Password is required",
							},
						]}
					>
						<Input
							style={{
								width: "100%",
							}}
							type="password"
							name="operatorCode"
							className="ret-form-input-login"
							placeholder="Password"
							autoComplete="off"
							required
						/>
					</Form.Item>

					<div style={{ color: "red", marginTop: "35%" }}>{error}</div>

					<Button
						htmlType="submit"
						type="primary"
						style={{
							width: "100%",
							height: "45px",
							borderRadius: "2px",
							backgroundColor: "#000",
							color: "white",
							fontSize: "16px",
							// fontFamily: 'Gotham',
							fontWeight: "700",
							marginBottom: "30px",
						}}
					>
						Next
					</Button>
				</Form>
			</div>
		</div>
	);
};

export default OpLogin;
