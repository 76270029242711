import { useRef, useState } from 'react';
import deleteBtn from '../../../../images/deleteBtn.svg';
import uploadreceipt from '../../../../images/SalesRep/file-upload.svg';
import receipt1 from '../../../../images/receiptSample.png';
import { Button } from 'antd';

const StoreUploadReceipt = ({ onFinish }) => {
	const uploadref = useRef();
	const [imageUrl, setImageUrl] = useState('');
	const [checkImage, setCheckImage] = useState(false);
	const [imageFile, setImageFile] = useState();

	const handleDisplayImage = (e) => {
		let render = new FileReader();
		if (e.target.files[0]) {
			setImageFile(e.target.files[0]);
			setCheckImage(false);
			render.readAsDataURL(e.target.files[0]);
			render.onload = (res) => {
				setImageUrl(res.target.result);
			};
		}
	};

	const onSubmit = async (e) => {
		e.preventDefault();

		if (imageFile) {
			setCheckImage(false);
		} else {
			if (!imageFile) {
				setCheckImage(true);
			}
		}

		onFinish(imageFile);
	};

	return (
		<>
			{imageUrl ? (
				<div
					className="mt-3"
					style={{
						cursor: 'pointer',
					}}
					onClick={() => uploadref.current.click()}
				>
					<div
						style={{
							position: 'relative',
						}}
						className="after-upload-receipt-container"
					>
						<img
							src={deleteBtn}
							alt="delete_btn"
							style={{
								width: '35px',
								position: 'absolute',
								top: '30px',
								right: '10px',
								cursor: 'pointer',
							}}
							onClick={() => {
								setImageUrl('');
							}}
						/>
						<img src={imageUrl} alt="receipt" style={{
							width: '100%',
							objectFit: 'cover',
						}} />
					</div>
				</div>
			) : (
				<div style={{ display: 'flex', justifyContent: 'center' }}>
					<div className="receipt-upload-container mt-3">
						<div
							style={{
								display: 'flex',
								flexDirection: 'column',
								alignItems: 'center',
							}}
							onClick={() => uploadref.current.click()}
						>
							<img src={uploadreceipt} alt="" style={{ width: '15%' }} />
							<p className="t-black upload-receipt-font">
								Upload a photo of your receipt
							</p>
						</div>
					</div>
				</div>
			)}

			{checkImage && (
				<span
					style={{
						color: '#ff4d4f',
						fontSize: '14px',
						textAlign: 'center',
						display: 'block',
					}}
				>
					Please upload your receipt image!
				</span>
			)}

			<div>
				<input
					type="file"
					name="receiptImage"
					accept="image/*"
					ref={uploadref}
					onChange={(e) => handleDisplayImage(e)}
					style={{ width: '0px', height: '0px', opacity: '0' }}
					required
				/>
			</div>

			<div
				className="mt-3"
				style={{
					borderRadius: '10px',
					border: '3px solid #30EA03',
					margin: '0 auto',
					width: '320px',
					padding: '10px',
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'center',
					alignItems: 'center',
				}}
			>
				<h2>Receipt Sample</h2>
				<p className="text-center">
					Sample of physical <br /> receipt:
				</p>
				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'center',
						alignItems: 'center',
					}}
				>
					<img
						src={receipt1}
						alt=""
						style={{
							width: '130%',
						}}
					/>
				</div>
			</div>

			<div
				style={{
					width: '100%',
					margin: '30px auto',
					textAlign: 'center',
				}}
			>
				<Button
					onClick={onSubmit}
					disabled={!imageFile}
					htmlType="button"
					type="primary"
					style={{
						width: '100%',
						maxWidth: '291px',
						height: '45px',
						borderRadius: '2px',
						backgroundColor:  imageFile? '#000' : '#9E9E9E',
						color: 'white',
						fontSize: '16px',
						// fontFamily: 'Gotham',
						fontWeight: '700',
						marginTop: '30px',
					}}
				>
					Submit
				</Button>
			</div>
		</>
	);
};

export default StoreUploadReceipt;
