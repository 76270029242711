
 export const config = {
    phase1: {
      label:'1April - 31July',
      startDate: new Date('2024-04-01T00:00:00+08:00'),
      endDate: new Date('2024-07-31T23:59:59+08:00'),
      campaignId: '37b75a6b-46ad-4d70-92a5-1efe86315b5c',
      prevCampaignId:null
    },
    phase2: {
      label:'1Aug - 31Dec',
      startDate: new Date('2024-08-01'),
      endDate: new Date('2024-12-31'),
      campaignId:'a8b3161e-61d1-44d4-966c-57f6e6320b54',
      prevCampaignId: '37b75a6b-46ad-4d70-92a5-1efe86315b5c'
    }
  }

/**
 * Retrieves the current phase based on the current date.
 *
 * @return {string|undefined} The current phase, or undefined if no phase is found.
 */
 export  const getCurrentConfigPhase=(today = new Date(),_config)=>{
    // check if today is within which phase for iterate throught configs to get current phase
     for(const [_,value] of Object.entries(_config)){
       if(value.startDate <= today && today <= value.endDate){
         return value
       }
     }
  }