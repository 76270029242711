import { useRef, useState } from "react";
import deleteBtn from "../../../../images/deleteBtn.svg";
import uploadreceipt from "../../../../images/SalesRep/file-upload.svg";
import receipt1 from "../../../../images/receiptSample.png";
import { Button } from "antd";

const StoreReUploadReceipt = ({ onFinish,errorMessage,onBack }) => {
	const uploadref = useRef();
	const [imageUrl, setImageUrl] = useState("");
	const [checkImage, setCheckImage] = useState(false);
	const [imageFile, setImageFile] = useState();

	const handleDisplayImage = (e) => {
		let render = new FileReader();
		if (e.target.files[0]) {
			setImageFile(e.target.files[0]);
			setCheckImage(false);
			render.readAsDataURL(e.target.files[0]);
			render.onload = (res) => {
				setImageUrl(res.target.result);
			};
		}
	};

	const onSubmit = async (e) => {
		e.preventDefault();

		if (imageFile) {
			setCheckImage(false);
		} else {
			if (!imageFile) {
				setCheckImage(true);
			}
		}

		onFinish(imageFile);
	};

	return (
		<>
			{imageUrl ? (
				<div
					className="mt-3"
					style={{
						cursor: "pointer",
					}}
					onClick={() => uploadref.current.click()}
				>
					<div
						style={{
							position: "relative",
						}}
						className="after-upload-receipt-container"
					>
						<img
							src={deleteBtn}
							alt="delete_btn"
							style={{
								width: "35px",
								position: "absolute",
								top: "30px",
								right: "10px",
								cursor: "pointer",
							}}
							onClick={() => {
								setImageUrl("");
							}}
						/>
						<img src={imageUrl} alt="receipt" style={{ maxWidth: "100%" }} />
					</div>
				</div>
			) : (
				<div style={{ display: "flex", justifyContent: "center" }}>
					<div className="receipt-upload-container mt-3">
						<div
							style={{
								display: "flex",
								flexDirection: "column",
								alignItems: "center",
							}}
							onClick={() => uploadref.current.click()}
						>
							<img src={uploadreceipt} alt="" style={{ width: "15%" }} />
							<p className="t-black upload-receipt-font">Upload a photo of your receipt</p>
						</div>
					</div>
				</div>
			)}

			<div>
				<input type="file" name="receiptImage" accept="image/*" ref={uploadref} onChange={(e) => handleDisplayImage(e)} style={{ width: "0px", height: "0px", opacity: "0" }} />
			</div>

			<div
				className="mt-3"
				style={{
					borderRadius: "10px",
					border: "3px solid #30EA03",
					margin: "0 auto",
					width: "320px",
					padding: "20px",
					display: "flex",
					flexDirection: "column",
					justifyContent: "center",
					alignItems: "center",
				}}
			>
				<h2>Receipt Sample</h2>
				<p className="text-center">
					Sample of physical <br /> receipt:
				</p>
				<div
					style={{
						display: "flex",
						flexDirection: "column",
						justifyContent: "center",
						alignItems: "center",
					}}
				>
					<img
						src={receipt1}
						alt=""
						style={{
							width: "130%",
						}}
					/>
				</div>
			</div>

			<div
				style={{
					width: "100%",
					margin: "30px auto",
					textAlign: "center",
				}}
			>
				{
					!!errorMessage && (
						<p style={{ color: "red", textAlign: "center" }}>{errorMessage}</p>
					)
				}
				<Button
					disabled={!imageFile}
					onClick={onSubmit}
					htmlType="button"
					type="primary"
					style={{
						maxWidth: "291px",
						width: "100%",
						height: "45px",
						borderRadius: "2px",
						backgroundColor: !!imageFile ? "#000" : "#9E9E9E",
						color: "white",
						fontSize: "16px",
						// fontFamily: 'Gotham',
						fontWeight: "700",
						marginTop: "30px",
					}}
				>
					Submit
				</Button>
				{/* <Button
					onClick={onBack}
					type="outline"
					style={{
						marginTop: "16px",
						maxWidth: "291px",
						width: "100%",
						height: "45px",
						borderRadius: "2px",
						border: "2px solid #9E9E9E",
						color: "#000",
						fontSize: "16px",
						// fontFamily: 'Gotham',
						fontWeight: "700",
					}}
				>
					Back
				</Button> */}
			</div>
		</>
	);
};

export default StoreReUploadReceipt;
