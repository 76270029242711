import React, { useState, useEffect } from 'react';
import { Form, Input, Select, Button } from 'antd';
import '../SalesRepRegisterStore.css';
import axiosClient from '../../../../utils/axiosClient';

const StoreDetailsForm = ({ onFinish = () => {} ,onBack=()=>{}}) => {
	const [formState] = Form.useForm();

	const [channels, setChannels] = useState([]);

	const getCNYCampainsChannelsAndSet = async () => {
		const res = await axiosClient.get('/haleon-cny/campaigns/active');
		if (res.data.length > 0) {
			const channels = [];

			// Get all channels from all campaigns
			res.data.forEach((campaign) =>
				campaign.config?.channels.forEach(
					(channel) => !channels.includes(channel) && channels.push(channel)
				)
			);

			setChannels(channels);
		}
	};

	useEffect(() => {
		getCNYCampainsChannelsAndSet();
	}, []);

	const handleOnFinish = (values) => onFinish(values);

	const formItemLabelStyle = {
		color: '#000',
		fontFamily: 'Hero New',
		fontSize: '14px',
		fontStyle: 'normal',
		fontWeight: 400,
		lineHeight: 'normal',
	};

	const formItemInputStyle = {
		borderRadius: '4px',
		height: '56px',
		padding: '2px 16px',
		alignItems: 'center',
	};

	const renderLabel = (label) => (
		<label style={formItemLabelStyle}>{label}</label>
	);

	const phoneNoErrorMessage = 'Please enter the phone number in the correct format (Eg: 60XXXXXXXX or 65XXXXXXXX)'

	return (
		<Form
			requiredMark={'optional'}
			style={{
				margin: '20px auto',
			}}
			layout="vertical"
			form={formState}
			initialValues={formState}
			onFinish={handleOnFinish}
		>
			<Form.Item
				label={renderLabel('Store Name (As Per Receipt):')}
				name="name"
				rules={[
					{
						required: true,
						message: 'Please enter name',
					},
				]}
			>
				<Input
					style={formItemInputStyle}
					className="form-item_input"
					placeholder="Enter store name..."
				/>
			</Form.Item>

			<Form.Item
				label={renderLabel('Retailer’s Phone Number:')}
				name="phone"
				type="number"
				rules={[
					{
						validator: (_, value) => {
							if (!value) {
								return Promise.reject(new Error(phoneNoErrorMessage));
							} else if (value.startsWith("65")) {
								if (!/^(65[0-9]{8})$/.test(value)) {
									return Promise.reject(new Error(phoneNoErrorMessage));
								}
							} else if (value.startsWith("60")) {
								if (!/^(601[0-9]{8,9})$/.test(value)) {
									return Promise.reject(new Error(phoneNoErrorMessage));
								}
							} else {
								return Promise.reject(new Error(phoneNoErrorMessage));
							}

							return Promise.resolve();
						},
					},
				]}
			>
				<Input
					style={formItemInputStyle}
					className="form-item_input"
					placeholder="Enter phone number..."
				/>
			</Form.Item>

			<Form.Item
				label={renderLabel('Channel:')}
				name="channel"
				rules={[
					{
						required: true,
						message: 'Please select channel',
					},
				]}
			>
				<Select
					placeholder="Select channel..."
					bordered={false}
					style={{ ...formItemInputStyle, padding: 0 }}
					className="form-item_input"
				>
					{React.Children.toArray(
						channels.map((channel) => (
							<Select.Option value={channel}>{channel}</Select.Option>
						))
					)}
				</Select>
			</Form.Item>

			<Form.Item
				label={renderLabel('Address:')}
				name="address"
				rules={[
					{
						required: true,
						message: 'Please enter address',
					},
				]}
			>
				<Input.TextArea
					placeholder="Enter address..."
					style={{
						...formItemInputStyle,
						minHeight: '80px',
						paddingTop: '20px',
					}}
					className="form-item_input"
				/>
			</Form.Item>

			{/* Add form submit button */}
			<Form.Item
				style={{
					width: '100%',
					margin: '30px auto',
					textAlign: 'center',
				}}
			>
				<Button
					htmlType="submit"
					type="primary"
					style={{
						maxWidth: '291px',
						width: '100%',
						height: '45px',
						borderRadius: '2px',
						backgroundColor: '#000',
						color: 'white',
						fontSize: '16px',
						// fontFamily: 'Gotham',
						fontWeight: '700',
						marginTop: '30px',
					}}
				>
					Next
				</Button>
				<Button
					onClick={onBack}
					type="outline"
					style={{
						marginTop: '16px',
						maxWidth: '291px',
						width: '100%',
						height: '45px',
						borderRadius: '2px',
						border: '2px solid #9E9E9E',
						color: '#000',
						fontSize: '16px',
						// fontFamily: 'Gotham',
						fontWeight: '700',
					}}
				>
					Back
				</Button>
			</Form.Item>

		</Form>
	);
};

export default StoreDetailsForm;
