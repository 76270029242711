import { useNavigate } from 'react-router-dom';
import logoutBtn from '../../images/logout.png';
import haleonLogo from '../../images/SalesRep/logo-haleon.png';

const SalesRepLayout = ({ children, showLogoutBtn = false }) => {
	const navigate = useNavigate();

	const logOut = () => {
		localStorage.removeItem('retailer-token');
		navigate('/operator/login'+ window.location.search);
	};

	return (
		<div
			className="ret-login-home"
			style={{
				minWidth: '280px',
				minHeight: '100vh',
			}}
		>
			<div
				style={{
					position: 'relative',
				}}
			>
				<div
					style={{
						display: 'flex',
						flexDirection: 'column',

						width: '100%',

						position: 'relative',
					}}
				>
					<div
						style={{
							position: 'absolute',
							top: '0',
							left: '0',
						}}
					>
						<svg
							width="38"
							height="127"
							viewBox="0 0 38 127"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<line y1="9.5" x2="38" y2="9.5" stroke="black" />
							<line y1="22.5" x2="38" y2="22.5" stroke="black" />
							<line y1="35.5" x2="38" y2="35.5" stroke="black" />
							<line y1="48.5" x2="38" y2="48.5" stroke="black" />
							<line y1="61.5" x2="38" y2="61.5" stroke="black" />
							<line
								y1="74.75"
								x2="38"
								y2="74.75"
								stroke="black"
								stroke-width="0.5"
							/>
							<line
								y1="87.75"
								x2="38"
								y2="87.75"
								stroke="black"
								stroke-width="0.5"
							/>
							<line
								y1="100.75"
								x2="38"
								y2="100.75"
								stroke="black"
								stroke-width="0.5"
							/>
							<line
								y1="113.75"
								x2="38"
								y2="113.75"
								stroke="black"
								stroke-width="0.5"
							/>
							<line
								y1="126.75"
								x2="38"
								y2="126.75"
								stroke="black"
								stroke-width="0.5"
							/>
						</svg>
					</div>

					<div
						style={{
							width: '38px',
							height: '42px',
							background: '#30EA03',

							alignSelf: 'end',
						}}
					/>
					<div>
						<div
							style={{
								width: 'calc(100% - 38px)',
								height: '42px',
								background: '#30EA03',

								alignSelf: 'end',
							}}
						/>
						<div
							style={{
								width: '38px',
								height: '42px',
								background: 'white',

								alignSelf: 'end',
							}}
						/>
					</div>
				</div>

				{/* HEADING */}
				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
					}}
				>
					<img
						src={haleonLogo}
						alt="haleon logo"
						style={{
							width: '248px',
							height: 'auto',
						}}
					/>
					<h3
						style={{
							color: 'var(--Main-Black, #000)',
							textAlign: 'center',
							fontFamily: 'Hero New',
							fontSize: '20px',
							fontStyle: 'normal',
							fontWeight: 700,
							lineHeight: 'normal',
						}}
					>
						Sales Representative Portal
					</h3>
				</div>

				{showLogoutBtn && (
					<img
						src={logoutBtn}
						alt="logout_btn"
						onClick={logOut}
						style={{
							width: '35px',
							height: '35px',

							position: 'absolute',
							right: '20px',
							top: '46px',
						}}
					/>
				)}
			</div>

			{/* CHILDREN */}
			<div>{children}</div>
		</div>
	);
};

export default SalesRepLayout;
