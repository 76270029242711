import { useEffect } from 'react';
import './SalesRep.css';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchDsrProfile } from '../../reducer/dsr/dsrActions';
import DraggableWhatsapp from '../../components/DraggableWhatsapp';
import tncPdf from '../../assets/Haleon Sales Rep Portal T&C_V1_20231220.pdf';

const SalesRepHome = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const { dsrProfile, isLoading } = useSelector((state) => state.dsr);

	useEffect(() => {
		dispatch(fetchDsrProfile());
	}, []);

	return (
		<div
			className="ret-login-home"
			style={{
				opacity: isLoading ? 0.5 : 1,
				pointerEvents: isLoading ? 'none' : 'all',

				height: '100%',
			}}
		>
			<DraggableWhatsapp />

			<div
				className="ml-3 mr-3"
				style={{
					marginTop: '60px',
				}}
			>
				<div style={{ lineHeight: '25px' }}>
					<span
						style={{
							color: '#000',
							fontFamily: 'Hero New',
							fontSize: '25px',
							fontStyle: 'normal',
							fontWeight: 900,
							lineHeight: 'normal',
						}}
					>
						Welcome,{' '}
					</span>
					<h3
						style={{
							color: '#000',
							fontFamily: 'Hero New',
							fontSize: '20px',
							fontStyle: 'normal',
							fontWeight: 500,
							lineHeight: 'normal',
						}}
					>
						{dsrProfile?.comUser?.name}
					</h3>
				</div>

				<div
					className="qr-homepage-container mt-5"
					style={{
						color: '#181818',
						textAlign: 'center',
						fontFamily: 'Hero New',
						fontSize: '30px',
						fontStyle: 'normal',
						fontWeight: 600,
						lineHeight: 'normal',

						height: '115px',
						borderRadius: '15px',
					}}
					onClick={() => navigate('/salesRep/stores/edit')}
				>
					<span>Edit Store Name</span>
				</div>

				<div
					style={{
						color: '#181818',
						textAlign: 'center',
						fontFamily: 'Hero New',
						fontSize: '30px',
						fontStyle: 'normal',
						fontWeight: 600,
						lineHeight: 'normal',

						height: '115px',
						borderRadius: '15px',
					}}
					className="qr-homepage-container mt-5"
					onClick={() => navigate('/salesRep/stores/register')}
				>
					<span>Register New Store</span>
				</div>
			</div>

			{/* FOOTER */}
			<div
				style={{
					width: '100%',
					margin: '198px 0 30px 0',

					display: 'flex',
					justifyContent: 'center',
				}}
			>
				<div
					style={{
						width: '248px',
						display: 'flex',
						justifyContent: 'space-between',
					}}
				>
					<a
						href={tncPdf}
						target='_blank'
						rel="noopener noreferrer"
						style={{
							color: '#000',
							textAlign: 'center',
							fontFamily: 'Hero New',
							fontSize: '12px',
							fontStyle: 'normal',
							fontWeight: 400,
							lineHeight: 'normal',
							textDecorationLine: 'underline',
						}}
					>
						Terms & Conditions
					</a>

					<a
						href={' https://www.privacy.haleon.com/en-my/general/'}
						style={{
							color: '#000',
							textAlign: 'center',
							fontFamily: 'Hero New',
							fontSize: '12px',
							fontStyle: 'normal',
							fontWeight: 400,
							lineHeight: 'normal',
							textDecorationLine: 'underline',
						}}
					>
						Privacy Policy
					</a>
				</div>
			</div>
		</div>
	);
};

export default SalesRepHome;
