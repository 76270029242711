import { Button } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useRef, useState } from "react";
import { dsrSession, logoutRetailer } from "../../reducer/retailerReducer";
import headerLogo from "../../images/SalesRep/SalesRepresentative.jpg";
import "./SalesRep.css";
import { useNavigate } from "react-router-dom";
import uploadreceipt from "../../images/SalesRep/file-upload.svg";
import deleteBtn from "../../images/deleteBtn.svg";
import receipt1 from "../../images/receiptSample.png";
import { useLocation } from "react-router-dom";
import logoutBtn from "../../images/logout.png";

const SalesRepUpload = () => {
  const uploadref = useRef();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const localtoken = localStorage.getItem("retailer-token");
  const { dashboardData } = useSelector((state) => state.retailer);
  const [imageUrl, setImageUrl] = useState("");

  const location = useLocation();
  const [imageFile, setImageFile] = useState();
  const [checkImage, setCheckImage] = useState(false);
  const [submit, setSubmit] = useState(false);
  const storeInfo = location.state?.values;
  const passQRCode = location.state?.summaryQR;

  useEffect(() => {
    dispatch(dsrSession({ token: localtoken }));
  }, []);

  const handleDisplayImage = (e) => {
    let render = new FileReader();
    if (e.target.files[0]) {
      setImageFile(e.target.files[0]);
      setCheckImage(false);
      render.readAsDataURL(e.target.files[0]);
      render.onload = (res) => {
        setImageUrl(res.target.result);
      };
    }
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    if (imageFile) {
      setCheckImage(false);

      const allValues = {
        retailerId: passQRCode,
        // storeId: localStorage.getItem('retailer-token'),
        // resubmitTranId: location.state && location.state,
      };

      try {
        const formData = new FormData();
        formData.append("image", imageFile);
        formData.append("sendData", JSON.stringify(allValues));
        setSubmit(true);
        navigate("/salesRep/summary", {
          state: { storeInfo, passQRCode, imageFile },
        });
      } catch (err) {
        console.log(err);
        console.log("catch error");
      }
    } else {
      if (!imageFile) {
        setCheckImage(true);
      }
    }
  };

  const logOut = () => {
    dispatch(logoutRetailer());
    navigate("/operator/login" + window.location.search);
  };


  return (
    <div className="ret-login-home">
      <div>
        <img src={headerLogo} alt="" className="w-100" />
        <img
        src={logoutBtn}
        alt="logout_btn"
        className="logout-button"
        onClick={logOut}
      />
      </div>
      <div className="ml-3 mr-3 mt-2" style={{ paddingBottom: "30px" }}>
        <div>
          <h1
            style={{ fontSize: "25px", fontWeight: "700" }}
            className="text-center"
          >
            One Last Step...
          </h1>
        </div>

        <div className="text-center">
          <div className="mt-2">
            <p className="text-center">
              Upload any receipt of this store for the
              <br /> validation purpose
            </p>
          </div>

          {imageUrl ? (
            <div className="mt-3">
              <div
                style={{
                  position: "relative",
                }}
                className="after-upload-receipt-container"
              >
                <img
                  src={deleteBtn}
                  alt="delete_btn"
                  style={{
                    width: "35px",
                    position: "absolute",
                    top: "30px",
                    right: "10px",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setImageUrl("");
                  }}
                />
                <img
                  src={imageUrl}
                  alt="receipt"
                  onClick={() => uploadref.current.click()}
                  style={{ width: "100%" }}
                />
              </div>
            </div>
          ) : (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <div className="receipt-upload-container mt-3">
                <div
                  style={{ alignItems: "center" }}
                  onClick={() => uploadref.current.click()}
                >
                  <img src={uploadreceipt} alt="" style={{ width: "15%" }} />
                  <p className="t-black upload-receipt-font">
                    Upload a photo of your receipt
                  </p>
                </div>
              </div>
            </div>
          )}

          {checkImage && (
            <span
              style={{
                color: "#ff4d4f",
                fontSize: "14px",
                textAlign: "center",
                display: "block",
              }}
            >
              Please upload your receipt image!
            </span>
          )}

          <div>
            <input
              type="file"
              name="receiptImage"
              accept="image/*"
              ref={uploadref}
              onChange={(e) => handleDisplayImage(e)}
              style={{ width: "0px", height: "0px", opacity: "0" }}
              required
            />
          </div>

          <div className="mt-3 receipt-sample-container">
            <div className="mt-1">
              <h2>Receipt Sample</h2>
              <p className="text-center">Sample of physical receipt:</p>
            </div>
            <div>
              <img src={receipt1} alt="" className="w-100" />
            </div>
          </div>

          <div className="mt-3">
            <Button
              style={{
                borderRadius: "2px",
                background: "rgba(87, 87, 87, 0.58)",
                width: "291px",
                height: "44px",
                border: "none",
                color: "#FFF",
                fontSize: "16px",
                fontWeight: "700",
                marginBottom: "50px",
              }}
              onClick={onSubmit}
            >
              Submit
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SalesRepUpload;
