import axios from "axios";

const axiosClient = axios.create({
  baseURL: process.env.REACT_APP_API_DOMAIN_URL,
  headers: {
    "x-company-id": process.env.REACT_APP_COMPANY_ID,
  },
});

axiosClient.interceptors.request.use(
  (config) => {
    let _config = config;
    /* if (process.env.REACT_APP_COMPANY_ID) {
			config.headers['company-id'] = process.env.REACT_APP_COMPANY_ID;
		
		if (config?.data) {
			config.data.companyId = process.env.REACT_APP_COMPANY_ID;
		} */
    const companyId = localStorage.getItem("x-company-id");
    if (companyId) {
      _config = {
        ..._config,
        headers: {
          ...config.headers,
          "x-company-id": companyId,
        },
      };
    }

    // RETRIVE RESPECTIVE TOKENS FOR EACH USER TYPE
    let token = localStorage.getItem("dsr-token");

    if (config.url?.split("/")[1] === "retailer") {
      token = localStorage.getItem("retailer-token");
    }
    if (token) {
      _config.headers["Authorization"] = `Bearer ${token}`;
    }

    return _config;
  },
  (error) => {
    console.log("error", error);
    return Promise.reject(error);
  }
);

export default axiosClient;
