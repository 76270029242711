import "../../css/Login.css";
import React, { useState, useEffect } from "react";
import { Button } from "antd";
// import { useDispatch } from "react-redux";
import headerLogo from "../../images/Retailer/onboarding (1).png";
import { useNavigate, useLocation } from "react-router-dom";
import logoutBtn from "../../images/logout.png";
import rankingImg from "../../images/ranking.png";
import axiosClientEncryption from "../../utils/axiosClientEncrytion";
import { config, getCurrentConfigPhase } from "../../utilities/config";
const StoreAccountRanking = () => {
  // const dispatch = useDispatch();
  const navigate = useNavigate();
  // const location = useLocation();
  const [retailerInfo, setRetailerInfo] = useState([]);
  const [retailerRanking, setRetailerRanking] = useState([]);
  const [retailerChannel, setRetailerChannel] = useState();
  const [firstRetailer, setFirstRetailer] = useState();
  const [secondRetailer, setSecondRetailer] = useState();
  const [thirdRetailer, setThirdRetailer] = useState();
  const [retailerData, setRetailerData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const profileRes = await axiosClientEncryption.get("/retailer/profile");
        let retailers = profileRes?.data?.retailers;
        let retailerIds = []; // Array to store all  IDs
        let retailerId = null;
        for (const retailer of retailers) {
          if (!retailer.name.includes("Master")) {
            retailerId = retailer.id;
          }
          retailerIds.push(retailer.id);
        }
        setRetailerInfo(retailerIds.length > 1 ? retailerIds : retailerId);
        setRetailerChannel(retailers[0].channel);

        const currentPhase = getCurrentConfigPhase(new Date(),config);
        if (
         ! currentPhase
        ) {
          throw new Error("Current phase config not found");
        }

        const campaignId = currentPhase.campaignId;
        const checkRetailerRankingRes = await axiosClientEncryption.get(
          `/haleon/retailer/ranks/recruitment-users?retailerId=${profileRes?.data?.retailers[0]?.id}`,
          {
            params: {
              campaignId,
              returnAllRetailers: true,
            },
          }
        );

        let rankingDetails = checkRetailerRankingRes?.data?.data?.ranks;
        let retailerDetails = checkRetailerRankingRes?.data?.data?.retailers;
        // setRetailerRanking(checkRetailerRankingRes?.data?.data);
        const combinedData = rankingDetails.map((rank, idx) => { 
          const retailer = retailerDetails.find(
            (retailer) => retailer.id === rank.id
          );
          return { ...retailer, ...rank };
        })

        const filteredData = combinedData
        // [2024-07-26] allow master retailer to be part of recruitment ranking
        //   .filter(
        //   (retailer) => !retailer.name.toLowerCase().includes("master")
        // );
        

        setRetailerData(filteredData);
        // console.log("Retailer Ranking:", checkRetailerRankingRes?.data?.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (retailerData) {
      setFirstRetailer(retailerData[0]?.id);
      setSecondRetailer(retailerData[1]?.id);
      setThirdRetailer(retailerData[2]?.id);
    }
  }, [retailerData]);

  const logOut = () => {
    localStorage.removeItem("retailer-token");
    navigate("/operator/login");
  };

  return (
    <div style={{ paddingBottom: "8%", fontFamily: "Hero New, sans-serif" }}>
      <img src={headerLogo} alt="" className="ret-login-logo"></img>
      <img
        src={logoutBtn}
        alt="logout_btn"
        onClick={logOut}
        className="logout-button"
      />
      {retailerInfo && retailerInfo.length ? (
        <div className="ml-2 mr-2">
          <div>
            <div style={{ position: "relative", textAlign: "center" }}>
              <div
                style={{
                  display: "flex",
                  position: "absolute",
                  top: "-10px",
                  width: "100%",
                  zIndex: "10",
                }}
              >
                <p
                  style={{
                    fontWeight: "600",
                    fontSize: "10px",
                    top: "8px",
                    position: "relative",
                    width: "33%",
                  }}
                >
                  {retailerInfo &&
                  retailerInfo.length &&
                  retailerData[1]?.rank !== null &&
                  retailerChannel !== "IP"
                    ? retailerData[1]?.extendedData?.label || retailerData[1]?.name
                    : retailerChannel === "IP" &&
                      secondRetailer === retailerInfo
                    ? retailerData[1]?.name
                    : (retailerData[1]?.rank === null &&
                        retailerChannel !== "IP") ||
                      (retailerData[1]?.rank === null &&
                        retailerChannel === "IP")
                    ? null
                    : "Retailer Store 2"}
                </p> 
                <p
                  style={{ fontWeight: "600", fontSize: "10px", width: "33%" }}
                >
                  {" "}
                  {retailerInfo &&
                  retailerInfo.length &&
                  retailerData[0]?.rank !== null &&
                  retailerChannel !== "IP" &&
                  retailerData[0]?.extendedData?.label !== null
                    ? retailerData[0]?.extendedData.label
                    : retailerChannel === "IP" && firstRetailer === retailerInfo
                    ? retailerData[0]?.name
                    : (retailerData[0]?.rank === null &&
                        retailerChannel !== "IP") ||
                      (retailerData[0]?.rank === null &&
                        retailerChannel === "IP")
                    ? null
                    : "Retailer Store 1"}
                </p>
                <p
                  style={{
                    fontWeight: "600",
                    fontSize: "10px",
                    top: "25px",
                    position: "relative",
                    width: "33%",
                  }}
                >
                  {retailerInfo &&
                  retailerInfo.length &&
                  retailerData[2]?.rank !== null &&
                  retailerChannel !== "IP"
                    ? retailerData[2]?.extendedData?.label || retailerData[2]?.name
                    : retailerChannel === "IP" && thirdRetailer === retailerInfo
                    ? retailerData[2]?.name
                    : (retailerData[2]?.rank === null &&
                        retailerChannel !== "IP") ||
                      (retailerData[2]?.rank === null &&
                        retailerChannel === "IP")
                    ? null
                    : "Retailer Store 3"}
                </p>
              </div>
              <img
                src={rankingImg}
                alt=""
                style={{ width: "85%", position: "relative", top: "10px" }}
              />
              <div
                style={{
                  display: "flex",
                  position: "absolute",
                  bottom: "5px",
                  width: "100%",
                  justifyContent: "center",
                }}
              >
                <p
                  style={{
                    fontWeight: "600",
                    fontSize: "14px",
                    zIndex: "10",
                    color: retailerData[1]?.rank ? "black" : "transparent",
                    width: "33.33%",
                  }}
                >
                  {" "}
                  {retailerInfo &&
                    retailerInfo.length &&
                    `${retailerData[1]?.noOfRecruitedUsers} ${
                      retailerData[1]?.noOfRecruitedUsers > 1 ? "users" : "user"
                    }`}
                </p>
                <p
                  style={{
                    fontWeight: "600",
                    fontSize: "14px",
                    zIndex: "10",
                    color: retailerData[0]?.rank ? "black" : "transparent",
                    width: "33.33%",
                  }}
                >
                  {" "}
                  {retailerInfo &&
                    retailerInfo.length &&
                    // retailerData[0]?.rank &&
                    `${retailerData[0]?.noOfRecruitedUsers} ${
                      retailerData[0]?.noOfRecruitedUsers > 1 ? "users" : "user"
                    }`}
                </p>
                <p
                  style={{
                    fontWeight: "600",
                    fontSize: "14px",
                    zIndex: "10",
                    color: retailerData[2]?.rank ? "black" : "transparent",
                    width: "33.33%",
                  }}
                >
                  {" "}
                  {retailerInfo &&
                    retailerInfo.length &&
                    // retailerData[2]?.rank &&
                    `${retailerData[2]?.noOfRecruitedUsers} ${
                      retailerData[2]?.noOfRecruitedUsers > 1 ? "users" : "user"
                    }`}
                </p>
              </div>
            </div>
          </div>
          <div
            style={{
              boxShadow: "rgba(0, 0, 0, 0.16)",
              borderRadius: "8px",
              height: "450px",
              overflowY: "auto",
              borderBottomRightRadius: "8px",
            }}
          >
            <div
              style={{
                display: "flex",
                background: "rgba(48, 234, 3, 1)",
                height: "50px",
                alignItems: "center",
                gap: "19%",
                borderTopLeftRadius: "8px",
                borderTopRightRadius: "8px",
              }}
            >
              <p
                style={{
                  marginLeft: "5%",
                  fontSize: "16px",
                  flex: "1",
                  fontWeight: "bold",
                }}
              >
                Rank
              </p>
              <p
                style={{
                  fontSize: "16px",
                  textAlign: "center",
                  flex: "2",
                  fontWeight: "bold",
                }}
              >
                Store
              </p>
              <p
                style={{
                  fontSize: "16px",
                  paddingRight: "3%",
                  flex: "1",
                  fontWeight: "bold",
                }}
              >
                User Recruited
              </p>
            </div>
            <div style={{ maxHeight: "calc(100% - 50px)", overflowY: "auto" }}>
              {retailerData && retailerData.length ? (
                <>
                  {React.Children.toArray(retailerData
                    .map((getInfo, idx) => {
                    idx += 1;

                    return (
                      <div
                        style={{
                          display: "flex",
                          height: "55px",
                          alignItems: "center",
                          borderBottom:
                            idx === retailerData.length
                              ? "none"
                              : "1px solid #ddd",
                          textAlign: "center",
                          backgroundColor:
                            getInfo.id === retailerInfo
                              ? "rgb(173, 123, 255)"
                              : "none",
                          color:
                            getInfo.id === retailerInfo ? "white" : "black",
                          gap: "3%",
                        }}
                      >
                        <p
                          style={{
                            marginLeft: "3%",
                            fontSize: "14px",
                            color:
                              getInfo.id === retailerInfo
                                ? "white"
                                : "rgba(173, 123, 255, 1)",
                            fontWeight: "600",
                            width: "10%",
                          }}
                        >
                          {getInfo?.rank ? getInfo.rank : "N/A"}
                        </p>
                        <p
                          style={{
                            fontSize: "13px",
                            textAlign: "left",
                            width: "70%",
                          }}
                        >
                          {retailerChannel === "IP" &&
                          getInfo.id !== retailerInfo
                            ? `Retailer Store ${idx}`
                            : retailerChannel !== "IP"
                            ? getInfo?.extendedData?.label || getInfo?.name
                            : getInfo?.name}
                        </p>
                        <p style={{ fontSize: "16px", width: "20%" }}>
                          {getInfo?.noOfRecruitedUsers}
                        </p>
                      </div>
                    );
                  }))}
                </>
              ) : null}
            </div>
          </div>
        </div>
      ) : (
        "No ranking available yet"
      )}

      <div className="ml-2 mr-2">
        <Button
          style={{
            background: "#333F48",
            color: "white",
            fontWeight: "600",
            height: "45px",
            borderRadius: "8px",
            width: "100%",
            fontSize: "18px",
          }}
          className="mt-3"
          onClick={() => navigate(-1)}
        >
          Back
        </Button>
      </div>
    </div>
  );
};

export default StoreAccountRanking;
