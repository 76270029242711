import './SalesRepRegisterStore.css';
import { useEffect, useState } from 'react';
import { Tabs, message } from 'antd';
import StoreDetailsForm from './components/StoreDetailsForm';
import StoreUploadReceipt from './components/StoreUploadReceipt';
import { useNavigate } from 'react-router-dom';
import StoreCreatedSuccssModal from './components/StoreCreatedSuccssModal';
import axiosClient from '../../../utils/axiosClient';

const REGISTER_STORE_TABS = {
	DETAILS: 'details',
	UPLOAD_RECEIPT: 'upload_receipt',
};

const SalesRepRegisterStore = () => {
	const navigate = useNavigate();

	const [forceRender, setForceRender] = useState(1);
	const [tab, setTab] = useState(REGISTER_STORE_TABS.DETAILS);
	const [isLoading, setIsLoading] = useState(false);

	const [createdStoreId, setCreatedStoreId] = useState();
	const [storeDetails, setStoreDetails] = useState()
	const [isStoreCreated, setIsStoreCreated] = useState(false);
	const [errorMessage, setErrorMessage] = useState(null);

	useEffect(() => {
		if (errorMessage) {
			message.error(errorMessage);
		}
	}, [errorMessage]);

	const createNewStore = async (storeDetails) => {
		errorMessage && setErrorMessage(null);

		const { name, phone, channel, address } = storeDetails || {};
		if (!name || !phone || !channel || !address) {
			setErrorMessage('Please fill all the fields!');
			return;
		}

		setIsLoading(true);

		try {
			const { name, phone, channel, address } = storeDetails;

			const storeData = {
				name,
				phone,
				channel,
				address,
				storeCode: 'HL_CNY'
			};

			const storeCreateRes = await axiosClient.post(
				'/dsr/retailers',
				storeData
			);

			if (
				!storeCreateRes ||
				!storeCreateRes.data ||
				!storeCreateRes.data.data
			) {
				throw new Error('Error on creating store.');
			}

			const storeId = storeCreateRes.data.data.id;
			setCreatedStoreId(storeId);
			setStoreDetails(storeCreateRes.data.data);

			setTab(REGISTER_STORE_TABS.UPLOAD_RECEIPT);
			setForceRender((prev) => prev + 1);
		} catch (err) {
			console.error('Error occurred:', err);
			if (err.response && err.response.data && err.response.data.message) {
				setErrorMessage(err.response.data.message);
			} else {
				setErrorMessage('An error occurred while processing data.');
			}
		} finally {
			setIsLoading(false);
		}
	};

	const uploadReceipt = async (imageFile) => {
		errorMessage && setErrorMessage(null);

		try {
			if (!imageFile) {
				setErrorMessage('Please upload your receipt image!');
				return;
			}

			setIsLoading(true);

			// UPLOAD-RECEIPT
			const data = new FormData();
			data.append('image', imageFile);
			const updatedStore = await axiosClient.patch(
				`/dsr/retailers/${createdStoreId}/upload-receipt`,
				data,
				{
					headers: { 'Content-Type': 'multipart/form-data' },
				}
			);
			if (updatedStore?.data?.data) {
				setIsStoreCreated(true);
			}
		} catch (err) {
			console.error('Error occurred:', err);
			if (err.response && err.response.data && err.response.data.message) {
				setErrorMessage(err.response.data.message);
			} else {
				setErrorMessage('An error occurred while processing data.');
				message.error(err.response.data.message);
			}
		} finally {
			setIsLoading(false);
		}
	};

	return (
		<div
			style={{
				margin: '20px',
				opacity: isLoading ? 0.5 : 1,
				pointerEvents: isLoading ? 'none' : 'all',
			}}
		>
			<StoreCreatedSuccssModal
				storeCode={storeDetails?.storeCode}
				password={storeDetails?.password}
				show={isStoreCreated}
				onDismiss={() => {
					setIsStoreCreated(false);
					navigate('/salesRep/home');
				}}
			/>

			<Tabs key={forceRender} defaultActiveKey={tab} animated>
				<Tabs.TabPane tab="Tab 1" key={REGISTER_STORE_TABS.DETAILS}>
					<h1
						style={{
							textAlign: 'center',
							color: 'var(--blk, #1B1A1A)',
							fontFamily: 'Hero New',
							fontSize: '25px',
							fontStyle: 'normal',
							fontWeight: 700,
							lineHeight: 'normal',
						}}
					>
						Register New Store
					</h1>
					<StoreDetailsForm
						onBack={() => {
							navigate('/salesRep/home');
						}}
						onFinish={(values) => createNewStore(values)} />
					
				</Tabs.TabPane>
				<Tabs.TabPane
					tab="Tab 2"
					disabled
					key={REGISTER_STORE_TABS.UPLOAD_RECEIPT}
				>
					<h1
						style={{
							textAlign: 'center',
							color: 'var(--blk, #1B1A1A)',
							fontFamily: 'Hero New',
							fontSize: '25px',
							fontStyle: 'normal',
							fontWeight: 700,
							lineHeight: 'normal',
						}}
					>
						One Last Step...
					</h1>
					<p
						style={{
							textAlign: 'center',
						}}
					>
						Upload any receipt of this store for the
						<br /> validation purpose
					</p>

					<StoreUploadReceipt
						onBack={() => {
							navigate('/salesRep/home');
						}}
						onFinish={uploadReceipt} />
				</Tabs.TabPane>
			</Tabs>
		</div>
	);
};

export default SalesRepRegisterStore;
