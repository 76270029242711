import { Button, Form, Input, Modal, Select } from "antd";
import React, { useEffect, useState } from "react";
import "../../css/RetailerPortal.css";
import axiosClientEncryption from "../../utils/axiosClientEncrytion";
import moment from "moment";
import { config, getCurrentConfigPhase } from "../../utilities/config";

const RetailerEntitlement = ({ proceed, setProceed, t }) => {
  const currentPhase = getCurrentConfigPhase(new Date(), config);
  const [paymentModal, setPaymentModal] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState("TNG_EWALLET");
  const [isSuccess, setIsSuccess] = useState(false);
  const [form] = Form.useForm();
  const [errorMsg, setErrorMsg] = useState("");
  const [retailerId, setRetailerId] = useState("");
  const [loading, setLoading] = useState(false);

  const currentDate = moment();
  const month = currentDate.format("MMMM");

  useEffect(() => {
    const fetchData = async () => {
      const profileRes = await axiosClientEncryption.get("/retailer/profile");
      // console.log("profileRes", profileRes);
      // console.log("retailer id amount", profileRes?.data?.retailers.length);
      setRetailerId(profileRes?.data?.retailers[0]?.id);
      // console.log("retailerId", retailerId);
    };
    fetchData();
  }, [retailerId]);

  const onFinish = async (values) => {
    // console.log("payment method", paymentMethod);
    // console.log("values", values);
    if (values) {
      try {
        if (!currentPhase) {
          throw new Error("Current phase config not found");
        }
        const res = await axiosClientEncryption.post(
          "/haleon/retailer/submit-recruiting-fulfillment",
          {
            campaignId: currentPhase.campaignId,
            prevCampaignId: currentPhase.prevCampaignId,
            retailerId: retailerId,
            paymentMethod: paymentMethod,
            paymentMethodData: values,
          }
        );
        if (res && res.data) {
          setPaymentModal(false);
          setIsSuccess(true);
        }
      } catch (err) {
        setErrorMsg(err?.response?.data?.message ?? err);
        setTimeout(() => {
          setErrorMsg("");
        }, 2000);
      }
    }
  };

  const handleClick = () => {
    setLoading(true);
  };


  return (
    <div>
      <Modal
        open={proceed}
        centered
        header={null}
        footer={null}
        onCancel={() => setProceed(false)}
      >
        <div
          className="text-center mt-2"
          style={{ fontFamily: "Hero New, sans-serif" }}
        >
          <h2>Confirmation</h2>
          <div className="mt-1">
            <p className="fontSize-14">
              I confirm that I have reviewed the entitlement statement to ensure
              its accuracy, and I understand that the entitlement will be paid
              out to TnG eWallet or Bank Account by 31st of the {month}. I
              acknowledge that only company-authorized accounts are eligible for
              payment.
            </p>
            <p
              className="fontSize-14 mt-1"
              style={{
                textAlign: "center",
                marginLeft: "5%",
                marginRight: "5%",
              }}
            >
              By clicking “Agree & Proceed”, I agree to the terms and conditions
              set forth by HALEON Malaysia.
            </p>
          </div>
          <Button
            className="mt-1"
            style={{
              backgroundColor: "#FA00FF",
              color: "white",
              fontSize: "18px",
              border: "none",
              borderRadius: "5px",
              height: "40px",
              fontWeight: "600",
              width: "100%",
            }}
            onClick={() => {
              setProceed(false);
              setPaymentModal(true);
            }}
          >
            Agree & Proceed
          </Button>
        </div>
      </Modal>

      <Modal
        open={paymentModal}
        centered
        footer={null}
        width={345}
        onCancel={() => setPaymentModal(false)}
      >
        <div className="text-center mt-3" style={{ fontSize: "16px" }}>
          <h2>Select Payment Method</h2>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              gap: "10px",
              marginTop: "5%",
            }}
          >
            <Button
              className="payment-method-button"
              style={{
                backgroundColor:
                  paymentMethod === "TNG_EWALLET" ? "#30E700" : "white",
              }}
              onClick={() => setPaymentMethod("TNG_EWALLET")}
            >
              TNG eWallet
            </Button>
            <Button
              className="payment-method-button"
              style={{
                backgroundColor:
                  paymentMethod === "BANK_TRANSFER" ? "#30E700" : "white",
              }}
              onClick={() => setPaymentMethod("BANK_TRANSFER")}
            >
              Bank Transfer
            </Button>
          </div>
          {paymentMethod === "TNG_EWALLET" ? (
            <div className="mt-3">
              <Form layout="vertical" form={form} onFinish={onFinish}>
                <Form.Item
                  name="registeredPhoneNumber"
                  label={<label>Registered Company's Mobile Number:</label>}
                  rules={[
                    {
                      message: "Please enter your mobile number",
                    },
                  ]}
                >
                  <Input
                    placeholder="eg: 0123456789"
                    className="payment-method-input-box"
                    required
                  />
                </Form.Item>

                <Form.Item
                  name="registedName"
                  label={<label>Registered Company's Name: </label>}
                  rules={[
                    {
                      message: "Enter your text",
                    },
                  ]}
                >
                  <Input
                    placeholder="Enter your text"
                    className="payment-method-input-box"
                    // onChange={isTNGname}
                    required
                  />
                </Form.Item>
                {errorMsg && (
                  <p
                    style={{
                      color: "red",
                      marginTop: "-5%",
                      textAlign: "center",
                      fontSize: "12px",
                    }}
                  >
                    {errorMsg}
                  </p>
                )}
                <Button
                  loading={loading}
                  onClick={handleClick}
                  className="mt-1"
                  htmlType="submit"
                  style={{
                    fontSize: "18px",
                    backgroundColor: "#FA00FF",
                    color: "white",
                    border: "none",
                    borderRadius: "5px",
                    height: "40px",
                    fontWeight: "600",
                    width: "100%",
                  }}
                >
                  Proceed
                </Button>
              </Form>
            </div>
          ) : paymentMethod === "BANK_TRANSFER" ? (
            <div className="mt-3">
              <Form layout="vertical" form={form} onFinish={onFinish}>
                <Form.Item
                  name="bankName"
                  label={<label>Registered Company's Bank:</label>}
                  rules={[
                    {
                      message: "Please enter the bank name",
                    },
                  ]}
                >
                  <Input
                    placeholder="Enter your bank name (etc. ABC Bank)"
                    className="payment-method-input-box"
                    required
                  />
                </Form.Item>
                <Form.Item
                  name="bankAccountNumber"
                  label={<label>Registered Company's Account Number:</label>}
                  rules={[
                    {
                      message: "Please enter your account number",
                    },
                  ]}
                >
                  <Input
                    placeholder="*Personal Account is not allowed"
                    className="payment-method-input-box"
                    // onChange={isBankAcc}
                    required
                  />
                </Form.Item>
                {/* {errorBankAccNum ? (
                  <>
                    {" "}
                    <div
                      style={{
                        color: "red",
                        marginTop: "-5%",
                        textAlign: "left",
                      }}
                    >
                      {errorBankAccNum}
                    </div>
                    <br />
                  </>
                ) : null} */}
                <Form.Item
                  name="bankAccountHolderName"
                  label={<label>Registed Company's Name: </label>}
                  rules={[
                    {
                      message: "Enter your name",
                    },
                  ]}
                >
                  <Input
                    placeholder="Enter your name"
                    className="payment-method-input-box"
                    required
                  />
                </Form.Item>
                <Form.Item
                  name="registeredSSM"
                  label={<label>Registered SSM: </label>}
                  rules={[
                    {
                      message: "Enter your text",
                    },
                  ]}
                >
                  <Input
                    placeholder="Enter your SSM"
                    className="payment-method-input-box"
                    // onChange={isTNGname}
                    required
                  />
                </Form.Item>
                <Form.Item
                  name="swiftCode"
                  label={<label>Swift Code:</label>}
                  rules={[
                    {
                      message: "Enter your text",
                    },
                  ]}
                >
                  <Input
                    placeholder="Enter your swift code"
                    className="payment-method-input-box"
                    required
                  />
                </Form.Item>

                {errorMsg && (
                  <p
                    style={{
                      color: "red",
                      marginTop: "-5%",
                      textAlign: "center",
                      fontSize: "12px",
                    }}
                  >
                    {errorMsg}
                  </p>
                )}

                <Button
                  loading={loading}
                  onClick={handleClick}
                  className="retailer-modal-button mt-1"
                  htmlType="submit"
                  style={{
                    backgroundColor: "#FA00FF",
                    color: "white",
                    fontSize: "18px",
                    border: "none",
                    borderRadius: "5px",
                    height: "40px",
                    fontWeight: "600",
                    width: "100%",
                  }}
                >
                  Proceed
                </Button>
              </Form>
            </div>
          ) : null}
        </div>
      </Modal>

      <Modal
        open={isSuccess}
        centered
        header={null}
        footer={null}
        width={320}
        onCancel={() => setIsSuccess(false)}
      >
        <div className="text-center mt-2">
          <h2 style={{ fontSize: "24px" }}>Success</h2>
          <div className="mt-1">
            <p
              className="fontSize-16 text-center"
              style={{ lineHeight: "1.5" }}
            >
              The payout will be made on the
              <br /> upcoming 30th of the month.
            </p>
          </div>
          <Button
            className="mt-3"
            style={{
              fontSize: "18px",
              backgroundColor: "#FA00FF",
              color: "white",
              border: "none",
              borderRadius: "5px",
              height: "40px",
              fontWeight: "600",
              width: "100%",
            }}
            onClick={() => {
              setIsSuccess(false);
              window.location.reload();
            }}
          >
            Done
          </Button>
        </div>
      </Modal>
    </div>
  );
};

export default RetailerEntitlement;
