import { headerConfig, returnError, tokenConfig } from "./helperFunc";

import { GLOBAL_CLEAR_ERROR } from "./userDetailsReducer";
import axios from "axios";

export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";

const SET_LOADING = "SET_LOADING";
const CLEAR_ERROR = "CLEAR_ERROR";
const CLEAR_STORE = "CLEAR_STORE";
const SUCCESS_STORE = "SUCCESS_STORE";

const FAIL_DATA = "FAIL_DATA";
const USER_REGISTER_SUCCESS = "USER_REGISTER_SUCCESS";
const USER_VERIFY_SUCCESS = "USER_VERIFY_SUCCESS";
const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS";
const RESEND_EMAIL_SUCCESS = "RESEND_EMAIL_SUCCESS";
const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
const CHECK_SESSION_SUCCESS = "  CHECK_SESSION_SUCCESS";
const FETCH_SIZES_SUCCESS = "FETCH_SIZES_SUCCESS";
const FETCH_FORMAT_SUCCESS = "FETCH_FORMAT_SUCCESS";
const USER_STATUS_SUCCESS = "USER_STATUS_SUCCESS";
const SUCCESS_DATA = "SUCCESS_DATA";
const userUrlPath = "/api/user";
const CHECK_QR = "CHECK_QR";
const FETCH_HISTORY_SUCCESS = "FETCH_HISTORY_SUCCESS";
const FETCH_TIER_HISTORY_SUCCESS = "FETCH_TIER_HISTORY_SUCCESS";
const FETCH_CURRENT_VOUCHER_SUCCESS = "FETCH_CURRENT_VOUCHER_SUCCESS";

export const checkQr = (currentPath) => async (dispatch) => {
  try {
    dispatch({ type: CLEAR_STORE });
    dispatch({ type: SET_LOADING });
    const { data } = await axios.post(`/api/user/checkqr`, currentPath);
    // const { data } = await axios.post(`/api/user/checkqr`, currentPath);
    // console.log(data);
    dispatch({ type: SUCCESS_STORE, payload: data });
  } catch (error) {
    dispatch({ type: FAIL_DATA, payload: returnError(error) });
  }
};

export const checkUserSession = (currentPath) => async (dispatch) => {
  try {
    dispatch({ type: CLEAR_ERROR });
    dispatch({ type: SET_LOADING });
    const { data } = await axios.post(
      `${userUrlPath}/session`,
      {},
      tokenConfig()
    );

    dispatch({ type: CHECK_SESSION_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: FAIL_DATA, payload: returnError(error) });
  }
};

export const registerUser = (newUser) => async (dispatch) => {
  try {
    dispatch({ type: CLEAR_ERROR });
    dispatch({ type: SET_LOADING });

    const { data } = await axios.post(
      `/api/user/register`,
      newUser,
      headerConfig()
    );

    dispatch({ type: USER_REGISTER_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: FAIL_DATA, payload: returnError(error) });
  }
};

export const checkUserStatus = (newUser) => async (dispatch) => {
  try {
    dispatch({ type: CLEAR_ERROR });
    dispatch({ type: SET_LOADING });

    const { data } = await axios.post(`/api/user/checkUser`, newUser);

    dispatch({ type: USER_STATUS_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: FAIL_DATA, payload: returnError(error) });
  }
};

export const verifyUser = (token) => async (dispatch) => {
  try {
    dispatch({ type: CLEAR_ERROR });
    dispatch({ type: SET_LOADING });

    const { data } = await axios.post(
			`${userUrlPath}/verify-otp`,
			{ token },
			headerConfig()
		);
    // callback();
    dispatch({ type: USER_VERIFY_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: FAIL_DATA, payload: returnError(error) });
  }
};

export const forgotPassword = (email) => async (dispatch) => {
  try {
    dispatch({ type: CLEAR_ERROR });
    dispatch({ type: SET_LOADING });

    const { data } = await axios.post(
      `${userUrlPath}/password/forgot`,
      { email: email },
      headerConfig()
    );

    dispatch({ type: FORGOT_PASSWORD_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: FAIL_DATA, payload: returnError(error) });
  }
};

export const resendEmailAction = (email) => async (dispatch) => {
  try {
    dispatch({ type: CLEAR_ERROR });
    dispatch({ type: SET_LOADING });

    const { data } = await axios.post(
      `${userUrlPath}/resetEmail`,
      { email: email },
      headerConfig()
    );
    dispatch({ type: RESEND_EMAIL_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: FAIL_DATA, payload: returnError(error) });
  }
};

export const changePassword = (values) => async (dispatch) => {
  try {
    dispatch({ type: CLEAR_ERROR });
    dispatch({ type: SET_LOADING });

    const { data } = await axios.post(
      `${userUrlPath}/password/reset`,
      values,
      tokenConfig()
    );

    dispatch({ type: RESET_PASSWORD_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: FAIL_DATA, payload: returnError(error) });
  }
};

export const logoutUser = () => (dispatch) => {
  dispatch({ type: CLEAR_ERROR });
  dispatch({ type: SET_LOADING });
  localStorage.removeItem("user-token");
  dispatch({ type: LOGOUT_SUCCESS });
};

export const clearError = (values) => (dispatch) => {
  dispatch({ type: CLEAR_ERROR });
};

export const fetchHistory = (value) => async (dispatch) => {
  try {
    dispatch({ type: CLEAR_ERROR });
    dispatch({ type: SET_LOADING });

    // console.log('tokenConfig', tokenConfig());

    const { data } = await axios.post(
      `/api/transaction/history/fetch`,
      { value },
      tokenConfig()
    );

    dispatch({ type: FETCH_HISTORY_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: FAIL_DATA, payload: returnError(error) });
  }
};


export const fetchTierHistory = (value) => async (dispatch) => {
  try {
    dispatch({ type: CLEAR_ERROR });
    dispatch({ type: SET_LOADING });

    const { data } = await axios.post(
      `${userUrlPath}/tier/history/fetch`,
      {value},
      tokenConfig()
    );
    dispatch({ type: FETCH_TIER_HISTORY_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: FAIL_DATA, payload: returnError(error) });
  }
};

export const checkRegisterReward = (currentPath) => async (dispatch) => {
  try {
    dispatch({ type: CLEAR_ERROR });
    dispatch({ type: SET_LOADING });

    const { data } = await axios.post(
      `/api/user/reward/register/get`,
      {},
      tokenConfig()
    );
    // console.log(data);

    dispatch({ type: SUCCESS_DATA, payload: data });
  } catch (error) {
    dispatch({ type: FAIL_DATA, payload: returnError(error) });
  }
};
export const callUpload = (currentPath, multipart) => async (dispatch) => {
  try {
    dispatch({ type: CLEAR_ERROR });
    dispatch({ type: SET_LOADING });

    const { data } = await axios.post(
      `/api/transaction/receipt/upload`,
      currentPath,
      tokenConfig(multipart)
    );
    dispatch({ type: SUCCESS_DATA, payload: data });
  } catch (error) {
    dispatch({ type: FAIL_DATA, payload: returnError(error) });
  }
};


export const checkVouchers = (currentPath) => async (dispatch) => {
  try {
    dispatch({ type: CLEAR_ERROR });
    dispatch({ type: SET_LOADING });
    const { data } = await axios.get(
      `/api/vouchers/check-stocks`,
      tokenConfig()
    );
    dispatch({ type: FETCH_CURRENT_VOUCHER_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: FAIL_DATA, payload: returnError(error) });
  }
};

const initialState = {
  user: null,
  error: null,
  loading: true,
  code_group: null,
  pointshop: [],
  cartNow: 0,
  userToken: localStorage.getItem("user-token") || null,
  transactionData: [],
  uploadHistory: [],
  pointHistory: [],
  // displayOrderedItems: [],
  // displayItems: [],
  // displayVouchers: [],
};

export const userReducer = function (state = initialState, action) {
  // console.log(action);
  switch (action.type) {
    // this is for fetching loading time setter
    case SET_LOADING:
      return {
        ...state,
        loading: true,
      };

    case CLEAR_STORE:
      return {
        ...state,
        store: null,
        error: null,
      };
    case SUCCESS_STORE:
      // console.log("action.payload.refer", action.payload.refer);
      localStorage.setItem("referId", action.payload.refer);
      return {
        ...state,
        store: action.payload.data.id,
        refer: action.payload.refer,
      };
    case CLEAR_ERROR:
    case GLOBAL_CLEAR_ERROR:
      return {
        ...state,
        code_group: null,
        message: null,
        error: null,
      };

    case RESEND_EMAIL_SUCCESS:
      return {
        resendEmail: action.payload.message,
        loading: false,
        error: null,
      };

    case USER_VERIFY_SUCCESS:
      return {
        user: action.payload,
        title: action.payload.title,
        description: action.payload.description,
        loading: false,
        error: null,
      };

    case CHECK_SESSION_SUCCESS:
      return {
        ...state,
        userData: action.payload.userData,
        transactionData: action.payload.transactionData,
        point: action.payload.point,
        // displayOrderedItems: action.payload.displayOrderedItems,
        displayCartQuantity: action.payload.displayCartQuantity,
        displayItems: action.payload.displayItems,
        displayVouchers: action.payload.displayVouchers,
        loading: false,
        error: null,
      };

    case USER_REGISTER_SUCCESS:
      return {
        ...state,
        registered: action.payload.message,
        loading: false,
        error: null,
      };

    case USER_STATUS_SUCCESS:
      return {
        ...state,
        user: action.payload.user,
        token: action.payload.token,
        loading: false,
        error: null,
      };

    case FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        resendEmail: action.payload.message,
        loading: false,
        error: null,
      };

    case RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        password: action.payload,
        loading: false,
        error: null,
      };
    case FETCH_SIZES_SUCCESS:
      // console.log('action.payload', action.payload);
      return {
        ...state,
        sizes: action.payload.data,
        loading: false,
        error: null,
      };

    case FETCH_FORMAT_SUCCESS:
      return {
        ...state,
        formats: action.payload.data,
        loading: false,
        error: null,
      };

    case FETCH_HISTORY_SUCCESS:
      return {
        ...state,
        uploadHistory: action.payload.uploadHistory,
        pointHistory: action.payload.pointHistory,
        loading: false,
        error: null,
      };

      case FETCH_TIER_HISTORY_SUCCESS:
        return {
          ...state,
          tierHistory: action.payload.tierHistory,
          currentPoints: action.payload.currentPoints,
          expired: action.payload.expired,
          text: action.payload.text,
          requirePoint: action.payload.requirePoint,
          nextTier: action.payload.nextTier,
          loading: false,
          error: null,
        };

      case FETCH_CURRENT_VOUCHER_SUCCESS:
        // console.log(action.payload.data);
        return {
          ...state,
          voucherList: action.payload.data,
          loading: false,
          error: null,
        };

    // case FILTER_HISTORY_SUCCESS:
    //   return {
    //     ...state,
    //     uploadHistory: action.payload.uploadHistory,
    //     loading: false,
    //     error: null,
    //   }

    case SUCCESS_DATA:
      return {
        ...state,
        loading: false,
        message: action.payload.message,
      };
    case FAIL_DATA:
      localStorage.removeItem("referCode");
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case CHECK_QR:
      return {
        ...state,
      };
    case LOGOUT_SUCCESS:
      return initialState;  

    default:
      return state;
  }
};
