import React, { useEffect, useState } from "react";
import { Form, Input, Select, Button } from "antd";
import "../SalesRepEditStore.css";

const StoreEditDetailsForm = ({ initialValue, retailers = [], onFinish = () => {}, onBack = () => {} ,errorMessage}) => {
	const [formState] = Form.useForm();
	const handleOnFinish = (values) => onFinish(values);

	const [selectedId, setSelectedId] = useState();
	const [selectedRetailer, setSelectedRetailer] = useState();

	useEffect(() => {
		if (initialValue) {
			formState.setFieldsValue(initialValue);
		}
	}, [formState, initialValue]);

	useEffect(() => {
		if (selectedId) {
			const selectedRetailer = retailers.find((retailer) => retailer.id === selectedId);
			formState.setFieldsValue(selectedRetailer);
			setSelectedRetailer(selectedRetailer);
		}
	}, [formState, retailers, selectedId]);

	const formItemLabelStyle = {
		color: "#000",
		fontFamily: "Hero New",
		fontSize: "14px",
		fontStyle: "normal",
		fontWeight: 400,
		lineHeight: "normal",
	};

	const formItemInputStyle = {
		borderRadius: "4px",
		height: "56px",
		padding: "2px 16px",
		alignItems: "center",
	};

	const renderLabel = (label) => <label style={formItemLabelStyle}>{label}</label>;

	console.log("selectedId", !!selectedId);

	return (
		<Form
			requiredMark={false}
			style={{
				margin: "20px auto",
			}}
			layout="vertical"
			form={formState}
			initialValues={formState}
			onFinish={handleOnFinish}
		>
			<Form.Item
				name={"id"}
				label={renderLabel("Current Store name:")}
				rules={[
					{
						required: true,
						message: "Please select store name.",
					},
				]}
			>
				<Select value={selectedId} placeholder="Select store" bordered={false} style={{ ...formItemInputStyle, padding: 0 }} className="form-item_input" onChange={(value) => setSelectedId(value)}>
					{React.Children.toArray(
						retailers.map((retailer) => (
							<Select.Option value={retailer.id}>
								{retailer.name}
							</Select.Option>
						))
					)}
				</Select>
				<p
					style={{
						margin:"10px 0",
						fontFamily: "Hero New",
						fontSize: "12px",
						fontWeight: 400,
					}}
				>
					{selectedRetailer?.address}
				</p>
			</Form.Item>

			<Form.Item
				label={renderLabel("New Store Name:")}
				name="name"
				rules={[
					{
						required: true,
						message: "Please enter new store name",
					},
				]}
			>
				<Input style={formItemInputStyle} disabled={!selectedRetailer} className="form-item_input" placeholder="Enter store name..." />
			</Form.Item>

			{/* Add form submit button */}
			<Form.Item
				style={{
					width: "100%",
					margin: "30px auto",
					textAlign: "center",
				}}
			>
				{
					!!errorMessage && (
						<p style={{ color: "red", textAlign: "center" }}>{errorMessage}</p>
					)
				}
				<Button
					htmlType="submit"
					type="primary"
					style={{
						maxWidth: "291px",
						width: "100%",
						height: "45px",
						borderRadius: "2px",
						backgroundColor: !!selectedId ? "#000" : "#9E9E9E",
						color: "white",
						fontSize: "16px",
						// fontFamily: 'Gotham',
						fontWeight: "700",
						marginTop: "30px",
					}}
				>
					Next
				</Button>
				<Button
					onClick={onBack}
					type="outline"
					style={{
						marginTop: "16px",
						maxWidth: "291px",
						width: "100%",
						height: "45px",
						borderRadius: "2px",
						border: "2px solid #9E9E9E",
						color: "#000",
						fontSize: "16px",
						// fontFamily: 'Gotham',
						fontWeight: "700",
					}}
				>
					Back
				</Button>
			</Form.Item>
		</Form>
	);
};

export default StoreEditDetailsForm;
