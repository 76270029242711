import "./SalesRep.css";
import { Button, Form, Modal, Select } from "antd";
import { dsrSession, logoutRetailer, updateStoreDetails } from "../../reducer/retailerReducer";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import closeBtn from "../../images/SalesRep/Icon.svg";
import headerLogo from "../../images/SalesRep/HeaderSummary.jpg";
import successImg from "../../images/checkmark1.png";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import logoutBtn from "../../images/logout.png";


const SalesRepSummary = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const localtoken = localStorage.getItem("dsr-token");
  const { dashboardData, message } = useSelector((state) => state.retailer);
  const [errorMsg, setErrorMsg] = useState('');
  const [submit, setSubmit] = useState(false);
  const location = useLocation();
  const info = location.state?.storeInfo;
  const passQRCode = location.state?.passQRCode;
  const passImg = location.state?.imageFile;
  const [form] = Form.useForm();
  const [imageUrl, setImageUrl] = useState("");
  const [img, setImg] = useState("");


  // console.log(info);
  // console.log("passImg",passImg);
  // console.log(passQRCode);
  // console.log("img",imageUrl);

  // const { formData } = location.state;
  // const receiptData = JSON.parse(formData.sendData || '{}');
  // const receiptImg = receiptData.image;

  useEffect(() => {
    dispatch(dsrSession({ token: localtoken }));
  }, []);

  // useEffect(() => {
  //   if (message) {
  //     setSubmit(true);
  //   }
  // }, [message]);

  const onSubmit = () => {
    setSubmit(false);
    if(!passQRCode) {
      setErrorMsg('Code ID not found');
    } else if(!img) {
      setErrorMsg('Image not found');
    } else if(!info) {
      setErrorMsg('Please make sure you have fill in all the store details');
    }
    
    else {
    const getInfo = {
      id: passQRCode,
      name: info?.name,
      number: info?.number,
      code: info?.code,
      state: info?.state,
      img: img,
    };
    setSubmit(true);
    dispatch(updateStoreDetails(getInfo));
  }
  };

  useEffect(() => {
    let render = new FileReader();
    if (passImg) {
      setImg(passImg);
      render.readAsDataURL(passImg);
      render.onload = (res) => {
        setImageUrl(res.target.result);
      };
    }
  }, [passImg]);

  const logOut = () => {
    dispatch(logoutRetailer());
    navigate("/operator/login" + window.location.search);
  };


  return (
    <div className="ret-login-home">
      <div>
        <img src={headerLogo} alt="" className="w-100" />
        <img
        src={logoutBtn}
        alt="logout_btn"
        className="logout-button"
        onClick={logOut}
      />
      </div>
      <div className="ml-3 mr-3 mt-2" style={{ paddingBottom: "30px" }}>
        <div>
          <h1
            style={{ fontSize: "25px", fontWeight: "700" }}
            className="text-center"
          >
            Store Details
          </h1>
        </div>

        <div className="text-center">
          <div className="mt-2">
            <p className="text-center">Here are the details of the store.</p>
          </div>

          <div className="mt-3 summary-details-container">
            <div className="mt-2 ml-2 summary-details-font">
              <p>
                <b>QR Code ID:</b> {passQRCode}{" "}
              </p>
              <p>
                <b>Store Name:</b> {info?.name}{" "}
              </p>
              <p>
                <b>Store Phone Number:</b> {info?.number}{" "}
              </p>
              <p>
                <b>Store Code:</b> {info?.code}
              </p>
              <p>
                <b>State:</b> {info?.state}
              </p>
            </div>
          </div>

          <div className="mt-3">
            <div>
              <h2 style={{ textAlign: "left", fontSize: "16px" }}>
                Your Receipt:
              </h2>
            </div>
            <div className="mt-3 receipt-sample-container">
              <div>
                {imageUrl ? <img
                  src={imageUrl}
                  alt=""
                  style={{
                    height: "370px",
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                  }}
                />: null}
              </div>
            </div>


            <div className="mt-5">
              {errorMsg ? <p>{errorMsg}</p> : null}
              <Button
                style={{
                  borderRadius: "2px",
                  background: "#000",
                  width: "292px",
                  height: "39px",
                  border: "none",
                  color: "#FFF",
                  fontSize: "16px",
                  fontWeight: "700",
                  boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.25)",
                }}
                onClick={onSubmit}
              >
                Activate POSM
              </Button>
            </div>
          </div>
        </div>
      </div>

      <div>
        <Modal
          open={submit}
          centered
          header={null}
          footer={null}
          closable={true}
          onCancel={() => navigate("/salesRep/home")}
          width={300}
          closeIcon={
            <img src={closeBtn} className="summary-close-btn" alt="Close" />
          }
        >
          <div className="text-center">
            <img
              style={{ width: "30%" }}
              src={successImg}
              alt=""
              className="submit-success-icon"
            ></img>
            <div className="fontSize-20" style={{ fontWeight: "700" }}>
              Activated
            </div>
            <div className="submit-pending-modal-font mt-1">
              <p className="text-center">
                You may give the POSM to the Retailer.
              </p>
            </div>
          </div>
        </Modal>
      </div>
    </div>
  );
};

export default SalesRepSummary;
