// import axios from 'axios';
// Setup config/headers and token
export const returnError = (error) => {
	// console.log('error print here', error.response);
	if (error.response) {
		if (error.response.data) {
			// console.log('error.response.data', error.response.data);
			if (error.response.data.error === 'Token expired, please login again') {
				setTimeout(() => {
					return (window.location.href = '/login');
				}, 1000);
			} else {
				return error.response.data.error;
			}
		}
	}
	const errorMsg =
		error.response && error.response.data
			? error.response.data.error
			: 'Internal Error';
	return errorMsg;
};

const tokenConfig = (multipart) => {
	let token = localStorage.getItem('user-token');
	// console.log('token', token)
	// Headers
	const config = {
		headers: {
			'Content-Type': multipart ? 'multipart/form-data' : 'application/json',
		},
	};
	config.data.companyId = process.env.REACT_APP_COMPANY_ID;

	//if there is a token then add it to the header
	if (token) {
		// operator token
		config.headers['Authorization'] = `Bearer ${token}`;
	}

	return config;
};

const retailerTokenConfig = (tokenBody) => {
	let token = localStorage.getItem('retailer-token');
	// console.log('token', token)
	// Headers
	const config = {
		headers: {
			'Content-Type': 'application/json',
		},
	};

	config.data.companyId = process.env.REACT_APP_COMPANY_ID;
	//if there is a token then add it to the header
	if (token) {
		// operator token
		config.headers['Authorization'] = `Bearer ${token}`;
	}

	return config;
};

const dsrTokenConfig = (tokenBody) => {
	let token = localStorage.getItem('dsr-token');
	// console.log('token', token)
	// Headers
	const config = {
		headers: {
			'Content-Type': 'application/json',
		},
	};

	config.data.companyId = process.env.REACT_APP_COMPANY_ID;
	//if there is a token then add it to the header
	if (token) {
		// operator token
		config.headers['Authorization'] = `Bearer ${token}`;
	}

	return config;
};

const adminTokenConfig = (multipart) => {
	let token = localStorage.getItem('admin-token');
	// Headers
	const config = {
		headers: {
			'Content-Type': multipart ? 'multipart/form-data' : 'application/json',
		},
	};

	config.data.companyId = process.env.REACT_APP_COMPANY_ID;
	//if there is a token then add it to the header
	if (token) {
		// operator token
		config.headers['Authorization'] = `Bearer ${token}`;
	}

	return config;
};

const headerConfig = () => {
	return {
		headers: {
			'Content-Type': 'application/json',
		},
	};
};

/* const newAxios = axios.create({
	baseURL: process.env.REACT_APP_DOMAIN,
}); */

export {
	tokenConfig,
	headerConfig,
	adminTokenConfig,
	retailerTokenConfig,
	dsrTokenConfig,
};
