import { useState, useEffect, useRef } from "react";
import { Button, Form, Modal } from "antd";
import { useDispatch, useSelector } from "react-redux";
import headerLogo from "../../images/SalesRep/HeaderSalesRep.png";
import "./SalesRep.css";
import { useNavigate } from "react-router-dom";
// import QrScanner from "jsqr";
import queryString from "query-string";
import axios from "axios";
import rejected from "../../images/rejected.png";
import qrCode from "../../images/qrcode.svg";
import { logoutRetailer } from "../../reducer/retailerReducer";
import logoutBtn from "../../images/logout.png";
import {QrScanner} from '@yudiel/react-qr-scanner';

const SalesRepScanQR = () => {
  const navigate = useNavigate();
  const [camera, setCamera] = useState(false);
  const dispatch = useDispatch();
  const [inputValue, setInputValue] = useState("");
  const [errorModal, setErrorModal] = useState(false);
  const videoRef = useRef();
  const [qrCodeVerified, setQrCodeVerified] = useState(false);


  const tick = async (result) => {
      if (result) {
        const urlArray = result.split("?");
        const { id } = queryString.parse(urlArray[1]);
        try {
          const res = await axios.post("/api/retailer/checkNewQr", { id: id });
          if (res.data.isNewQr) {
            setInputValue(id);
            setQrCodeVerified(true);
          } else {
            setErrorModal(true);
          }
        } catch (error) {
          console.log("error", error);
          setErrorModal(true);
          // Prompt warning for invalid QR.
        }
      } 
  };

  const logOut = () => {
    dispatch(logoutRetailer());
    navigate("/operator/login" + window.location.search);
  };

  return (
    <div>
      <Modal
        open={errorModal}
        centered
        footer={null}
        closable={true}
        onCancel={() => setErrorModal(false)}
        width={300}
      >
        <div style={{ display: "flex", justifyContent: "center" }}>
          <img src={rejected} alt="" style={{ width: "20%" }}></img>
        </div>
        <br />
        <div
          style={{
            fontWeight: "bold",
            fontSize: "20px",
            textAlign: "center",
          }}
        >
          Invalid QR Code, please rescan a validate QR again
        </div>
      </Modal>
      <div className="ret-login-home">
        <div>
          <img src={headerLogo} alt="" className="w-100" />
          <img
        src={logoutBtn}
        alt="logout_btn"
        className="logout-button"
        onClick={logOut}
      />
        </div>
        <Form style={{ marginBottom: "10px" }}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              maxWidth: "500px",
            }}
          >
            {/* <div
              style={{
                position: "fixed",
                maxWidth: "500px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                zIndex: 1000,
              }}
            >
              <img src={qrCode} alt="" style={{ width: "70%" }} />
            </div> */}
            <QrScanner
            viewFinder={()=>   
            <div
              style={{
                zIndex: 1000,
                top: '0px',
                left: '0px',
                width: '100%',
                height: '100%',
                display: 'block',
                overflow: 'hidden',
                position: 'absolute',
              }}
            >
              <img src={qrCode} alt="" style={{ width: "100%" }} />
            </div>
            }
              onDecode={tick}
              onError={(error) => console.log(error?.message)}
              scanDelay={500}
            />
            
          </div>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              lineHeight: "400px",
            }}
          >
            <p
              className="t-black fontSize-16 mt-2"
              style={{
                maxWidth: "70%",
                lineHeight: "20px",
                fontWeight: "600",
                textAlign:'center',
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {qrCodeVerified
                ? `QR Code ID: ${inputValue}`
                : `Scan the QR code that is displayed on the POSM`}
            </p>
          </div>

          <div className="text-center">
            <Button
              type="primary"
              onClick={() => {
                if (qrCodeVerified) {
                  navigate("/salesRep/storeDetails", { state: {inputValue} });
                } else {
                  navigate("/salesRep/home");
                }
              }}
              className="mt-5"
              style={{
                width: "291px",
                height: "45px",
                borderRadius: "2px",
                backgroundColor: "#000",
                color: "white",
                fontSize: "16px",
                fontWeight: "700",
              }}
            >
              {qrCodeVerified ? `Next` : `Back`}
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default SalesRepScanQR;
