import axiosClient from '../../utils/axiosClient';
import { returnError } from '../helperFunc';
import { SET_DSR_PROFILE, SET_ERROR, SET_LOADING } from './dsrActionTypes';

export const fetchDsrProfile = (_params) => async (dispatch) => {
	try {
		dispatch({ type: SET_LOADING, payload: true });
		dispatch({ type: SET_ERROR, payload: null });

		const res = await axiosClient.get('/dsr/profile');
		dispatch({ type: SET_DSR_PROFILE, payload: res.data });
	} catch (error) {
		dispatch({ type: SET_ERROR, payload: returnError(error) });
	}
};
