import { adminTokenConfig } from "./helperFunc";
import axios from "axios";
import { returnError } from "./helperFunc";

const SET_LOADING = "SET_LOADING";
const CLEAR_ERROR = "CLEAR_ERROR";
const GET_BRANDS_SUCCESS = "GET_BRANDS_SUCCESS";
const GET_ITEMS_SUCCESS = "GET_ITEMS_SUCCESS";
const GET_NEWS_SUCCESS = "GET_NEWS_SUCCESS";
const ACTION_SUCCESS = "ACTION_SUCCESS";
const ACTION_FAILED = "ACTION_FAILED";
const GET_CODEBATCH_SUCCESS = "GET_CODEBATCH_SUCCESS";
const GET_VOUCHER_SUCCESS = "GET_VOUCHER_SUCCESS";
const UPLOAD_VOUCHER_SUCCESS = "UPLOAD_VOUCHER_SUCCESS";
const GET_DONATE_SUCCESS = "GET_DONATE_SUCCESS";
const adminUrlPath = "/api/admin";
const reportUrlPath = "/api/report";
const GET_SCAN_SUCCESS = "GET_SCAN_SUCCESS";
const GET_POINT_SUCCESS = "GET_POINT_SUCCESS";
const GET_TRAN_SUCCESS = "GET_TRAN_SUCCESS";
const GET_RATING_SUCCESS = "GET_RATING_SUCCESS";
const GET_RECEIPT_SUCCESS = "GET_RECEIPT_SUCCESS";
const GET_ACCOUNT_SUCCESS = "GET_ACCOUNT_SUCCESS";
const GET_STORE_PERFORMANCE_SUCCESS = "GET_STORE_PERFORMANCE_SUCCESS";
const GET_POINTS_EARNED_SUCCESS = "GET_POINTS_EARNED_SUCCESS";
const GET_ARTICLE_SUCCESS = "GET_ARTICLE_SUCCESS";
const ADMIN_LOGIN = "ADMIN_LOGIN";


export const clearError = () => async (dispatch) => {
  try {
    dispatch({ type: CLEAR_ERROR });
    dispatch({ type: SET_LOADING });
  } catch (error) {
    dispatch({ type: ACTION_FAILED, payload: returnError(error) });
  }
};
export const loginAdmin = (sendData) => async (dispatch) => {
  try {
    dispatch({ type: CLEAR_ERROR });
    dispatch({ type: SET_LOADING });

    const { data } = await axios.post(`${adminUrlPath}/user/login`, sendData);
    dispatch({ type: ADMIN_LOGIN, payload: data });
  } catch (error) {
    dispatch({ type: ACTION_FAILED, payload: returnError(error) });
  }
};

export const getBrands = () => async (dispatch) => {
  try {
    dispatch({ type: CLEAR_ERROR });
    dispatch({ type: SET_LOADING });

    const { data } = await axios.post(
      `${adminUrlPath}/brands/fetch`,
      {},
      adminTokenConfig()
    );
    dispatch({ type: GET_BRANDS_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: ACTION_FAILED, payload: returnError(error) });
  }
};

export const createBrands = (newData) => async (dispatch) => {
  try {
    dispatch({ type: CLEAR_ERROR });
    dispatch({ type: SET_LOADING });

    const { data } = await axios.post(
      `${adminUrlPath}/brands/create`,
      newData,
      adminTokenConfig()
    );
    dispatch({ type: ACTION_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: ACTION_FAILED, payload: returnError(error) });
  }
};
export const createFormats = (newData) => async (dispatch) => {
  try {
    dispatch({ type: CLEAR_ERROR });
    dispatch({ type: SET_LOADING });

    const { data } = await axios.post(
      `${adminUrlPath}/format/create`,
      newData,
      adminTokenConfig()
    );
    dispatch({ type: ACTION_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: ACTION_FAILED, payload: returnError(error) });
  }
};
export const createSizes = (newData) => async (dispatch) => {
  try {
    dispatch({ type: CLEAR_ERROR });
    dispatch({ type: SET_LOADING });

    const { data } = await axios.post(
      `${adminUrlPath}/brands/size/create`,
      newData,
      adminTokenConfig()
    );
    dispatch({ type: ACTION_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: ACTION_FAILED, payload: returnError(error) });
  }
};
export const getItems = (newData) => async (dispatch) => {
  try {
    dispatch({ type: CLEAR_ERROR });
    dispatch({ type: SET_LOADING });

    const { data } = await axios.post(
      `${adminUrlPath}/product/fetch`,
      newData,
      adminTokenConfig()
    );
    dispatch({ type: GET_ITEMS_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: ACTION_FAILED, payload: returnError(error) });
  }
};
export const createItems = (newData, image, imageBM, imageCH) => async (dispatch) => {
  try {
    dispatch({ type: CLEAR_ERROR });
    dispatch({ type: SET_LOADING });

    const { data } = await axios.post(
      `${adminUrlPath}/product/create`,
      newData,image, imageBM, imageCH,
      adminTokenConfig()
    );
    dispatch({ type: ACTION_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: ACTION_FAILED, payload: returnError(error) });
  }
};
export const updateItems = (newData) => async (dispatch) => {
  try {
    dispatch({ type: CLEAR_ERROR });
    dispatch({ type: SET_LOADING });

    const { data } = await axios.post(
      `${adminUrlPath}/product/update`,
      newData,
      adminTokenConfig()
    );
    dispatch({ type: ACTION_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: ACTION_FAILED, payload: returnError(error) });
  }
};
export const createCategory = (newData) => async (dispatch) => {
  try {
    dispatch({ type: CLEAR_ERROR });
    dispatch({ type: SET_LOADING });

    const { data } = await axios.post(
      `${adminUrlPath}/category/create`,
      newData,
      adminTokenConfig()
    );
    dispatch({ type: ACTION_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: ACTION_FAILED, payload: returnError(error) });
  }
};
export const getCodeBatch = () => async (dispatch) => {
  try {
    dispatch({ type: CLEAR_ERROR });
    dispatch({ type: SET_LOADING });

    const { data } = await axios.post(
      `${adminUrlPath}/codebatch/fetch`,
      {},
      adminTokenConfig()
    );
    dispatch({ type: GET_CODEBATCH_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: ACTION_FAILED, payload: returnError(error) });
  }
};
export const createCodeBatch = (newData) => async (dispatch) => {
  try {
    dispatch({ type: CLEAR_ERROR });
    dispatch({ type: SET_LOADING });

    const { data } = await axios.post(
      `${adminUrlPath}/codebatch/create`,
      newData,
      adminTokenConfig()
    );
    dispatch({ type: ACTION_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: ACTION_FAILED, payload: returnError(error) });
  }
};

export const getVoucher = () => async (dispatch) => {
  try {
    dispatch({ type: CLEAR_ERROR });
    dispatch({ type: SET_LOADING });

    const { data } = await axios.post(
      `${adminUrlPath}/voucher/fetch`,
      {},
      adminTokenConfig()
    );
    dispatch({ type: GET_VOUCHER_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: ACTION_FAILED, payload: returnError(error) });
  }
};
export const createVoucherType = (newData, image) => async (dispatch) => {
  try {
    dispatch({ type: CLEAR_ERROR });
    dispatch({ type: SET_LOADING });

    const { data } = await axios.post(
      `${adminUrlPath}/vouchertype/create`,
      newData, image,
      adminTokenConfig()
    );
    
    dispatch({ type: ACTION_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: ACTION_FAILED, payload: returnError(error) });
  }
};
export const createVoucherBatch = (newData) => async (dispatch) => {
  try {
    dispatch({ type: CLEAR_ERROR });
    dispatch({ type: SET_LOADING });

    const { data } = await axios.post(
      `${adminUrlPath}/voucher/batch/create`,
      newData,
      adminTokenConfig()
    );
    dispatch({ type: ACTION_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: ACTION_FAILED, payload: returnError(error) });
  }
};
export const uploadVoucherCSV = (newData) => async (dispatch) => {
  try {
    dispatch({ type: CLEAR_ERROR });
    dispatch({ type: SET_LOADING });

    const { data } = await axios.post(
      `${adminUrlPath}/voucher/upload/check`,
      newData,
      adminTokenConfig('multipart/form-data')
    );
    dispatch({ type: UPLOAD_VOUCHER_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: ACTION_FAILED, payload: returnError(error) });
  }
};

export const getTrans = (sendData) => async (dispatch) => {
  try {
    dispatch({ type: CLEAR_ERROR });
    dispatch({ type: SET_LOADING });

    const { data } = await axios.post(
      `${reportUrlPath}/transaction/fetch`,
      sendData,
      adminTokenConfig()
    );
    dispatch({ type: GET_TRAN_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: ACTION_FAILED, payload: returnError(error) });
  }
};

export const updateTrans = (newData) => async (dispatch) => {
  try {
    dispatch({ type: CLEAR_ERROR });
    dispatch({ type: SET_LOADING });

    const { data } = await axios.post(
      `${adminUrlPath}/transaction/update`,
      newData,
      adminTokenConfig()
    );
    dispatch({ type: ACTION_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: ACTION_FAILED, payload: returnError(error) });
  }
};

export const getPoint = (sendData) => async (dispatch) => {
  try {
    dispatch({ type: CLEAR_ERROR });
    dispatch({ type: SET_LOADING });

    const { data } = await axios.post(
      `${reportUrlPath}/pointhistory/fetch`,
      sendData,
      adminTokenConfig()
    );
    dispatch({ type: GET_POINT_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: ACTION_FAILED, payload: returnError(error) });
  }
};
export const getAccount = (sendData) => async (dispatch) => {
  try {
    dispatch({ type: CLEAR_ERROR });
    dispatch({ type: SET_LOADING });

    const { data } = await axios.post(
			`${reportUrlPath}/crm-users`,
			sendData,
			adminTokenConfig()
		);
    dispatch({ type: GET_TRAN_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: ACTION_FAILED, payload: returnError(error) });
  }
};
export const getStorePerformance = (sendData) => async (dispatch) => {
  try {
    dispatch({ type: CLEAR_ERROR });
    dispatch({ type: SET_LOADING });

    const { data } = await axios.post(
			`${reportUrlPath}/stores`,
			sendData,
			adminTokenConfig()
		);
    dispatch({ type: GET_STORE_PERFORMANCE_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: ACTION_FAILED, payload: returnError(error) });
  }
};
export const getPointsEarned = (sendData) => async (dispatch) => {
  try {
    dispatch({ type: CLEAR_ERROR });
    dispatch({ type: SET_LOADING });

    const { data } = await axios.post(
      `${reportUrlPath}/points/earned/fetch`,
      sendData,
      adminTokenConfig()
    );
    dispatch({ type: GET_POINTS_EARNED_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: ACTION_FAILED, payload: returnError(error) });
  }
};
export const getReceipt = (sendData) => async (dispatch) => {
  try {
    dispatch({ type: CLEAR_ERROR });
    dispatch({ type: SET_LOADING });

    const { data } = await axios.post(
			`${reportUrlPath}/receipts`,
			sendData,
			adminTokenConfig()
		);
    dispatch({ type: GET_RECEIPT_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: ACTION_FAILED, payload: returnError(error) });
  }
};
export const updateValidate = (sendData) => async (dispatch) => {
  try {
    dispatch({ type: CLEAR_ERROR });
    dispatch({ type: SET_LOADING });

    const { data } = await axios.post(
      `${adminUrlPath}/validate`,
      sendData,
      adminTokenConfig()
    );
    dispatch({ type: ACTION_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: ACTION_FAILED, payload: returnError(error) });
  }
};

export const createArticle = (newData,image, imageBM, imageCH) => async (dispatch) => {
  try {
    dispatch({ type: CLEAR_ERROR });
    dispatch({ type: SET_LOADING });

    const { data } = await axios.post(
      `${adminUrlPath}/article/create`,
      newData,image, imageBM, imageCH, 
      adminTokenConfig()
    );
    dispatch({ type: ACTION_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: ACTION_FAILED, payload: returnError(error) });
  }
};

export const getArticle = (newData) => async (dispatch) => {
  try {
    dispatch({ type: CLEAR_ERROR });
    dispatch({ type: SET_LOADING });

    const { data } = await axios.post(
      `${adminUrlPath}/article/fetch`,
      newData,
      adminTokenConfig()
    );
    dispatch({ type: GET_ARTICLE_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: ACTION_FAILED, payload: returnError(error) });
  }
};

export const updateArticle = (newData) => async (dispatch) => {
  try {
    dispatch({ type: CLEAR_ERROR });
    dispatch({ type: SET_LOADING });

    const { data } = await axios.post(
      `${adminUrlPath}/article/update`,
      newData,
      adminTokenConfig()
    );
    dispatch({ type: ACTION_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: ACTION_FAILED, payload: returnError(error) });
  }
};

export const getRating = (sendData) => async (dispatch) => {
  try {
    dispatch({ type: CLEAR_ERROR });
    dispatch({ type: SET_LOADING });

    const { data } = await axios.post(
      `${reportUrlPath}/rating/fetch`,
      sendData,
      adminTokenConfig()
    );
    dispatch({ type: GET_RATING_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: ACTION_FAILED, payload: returnError(error) });
  }
};

export const updateRating = (newData) => async (dispatch) => {
  try {
    dispatch({ type: CLEAR_ERROR });
    dispatch({ type: SET_LOADING });

    const { data } = await axios.post(
      `${adminUrlPath}/rating/update`, newData
    );
    dispatch({ type: ACTION_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: ACTION_FAILED, payload: returnError(error) });
  }
};

export const getPaymentHistory = (sendData) => async (dispatch) => {
  try {
    dispatch({ type: CLEAR_ERROR });
    dispatch({ type: SET_LOADING });

    const { data } = await axios.post(
      `${adminUrlPath}/payment/info/fetch`,
      sendData,
      adminTokenConfig()
    );

    dispatch({ type: GET_RATING_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: ACTION_FAILED, payload: returnError(error) });
  }
};


export const updatePayment = (newData) => async (dispatch) => {
  try {
    dispatch({ type: CLEAR_ERROR });
    dispatch({ type: SET_LOADING });

    const { data } = await axios.post(
      `${adminUrlPath}/payment/info/update`,
       newData
    );
    dispatch({ type: ACTION_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: ACTION_FAILED, payload: returnError(error) });
  }
};



const initialState = {
  admin: null,
  error: null,
  loading: false,
  adminToken: localStorage.getItem("admin-token") || null,
  brandList: [],
  formatList: [],
  itemList: [],

  voucherList: [],
  voucherTypeList: [],
  totalVouchers: [],
  voucherCodes: [],
  duplicateCodes: [],

  receiptList: [],
  transList: [],
  pointList: [],
  storeList: [],
  skuList: [],
  ratingList: [],
};

export const adminReducer = function (state = initialState, action) {
  switch (action.type) {
    // this is for fetching loading time setter
    case SET_LOADING:
      return {
        ...state,
        loading: true,
      };

    case CLEAR_ERROR:
      return {
        ...state,
        voucherCodes: [],
        duplicateCodes: [],
        error: null,
      };

    case GET_BRANDS_SUCCESS:
      return {
        ...state,
        brandList: action.payload.data,
        formatList: action.payload.formatList,
        loading: false,
        error: null,
      };

    case GET_ITEMS_SUCCESS:
      return {
        ...state,
        itemList: action.payload.data,
        categoryList: action.payload.categoryList,
        loading: false,
        error: null,
      };

    case GET_ARTICLE_SUCCESS:
      return {
        ...state,
        dataList: action.payload.dataList,
        articleCategories: action.payload.articleCategories,
        loading: false,
        error: null,
      };

    case GET_CODEBATCH_SUCCESS:
      return {
        ...state,
        codebatchList: action.payload.data,
        brandList: action.payload.brandList,
        formatList: action.payload.formatList,
        loading: false,
        error: null,
      };
    case GET_VOUCHER_SUCCESS:
      return {
        ...state,
        voucherList: action.payload.voucherList,
        voucherTypeList: action.payload.voucherTypeList,
        voucherBatchList: action.payload.voucherBatchList,
        loading: false,
        error: null,
      };

    case UPLOAD_VOUCHER_SUCCESS:
      return {
        ...state,
        csvData: action.payload.data,
        totalVouchers: action.payload.totalVouchers,
        voucherCodes: action.payload.voucherCodes,
        duplicateCodes: action.payload.duplicateCodes,
        loading: false,
        error: null,
      };

    case GET_RECEIPT_SUCCESS:
      return {
        ...state,
        receiptList: action.payload.data,
        storeList: action.payload.storeList,
        skuList: action.payload.skuList,
        loading: false,
        error: null,
      };
    case GET_DONATE_SUCCESS:
      return {
        ...state,
        donateList: action.payload.data,
        loading: false,
        error: null,
      };
    case GET_SCAN_SUCCESS:
      return {
        ...state,
        scanList: action.payload.data,
        loading: false,
        error: null,
      };
    case GET_TRAN_SUCCESS:
      return {
        ...state,
        transList: action.payload.data,
        loading: false,
        error: null,
      };
    case GET_RATING_SUCCESS:
      return {
        ...state,
        ratingList: action.payload.data,
        loading: false,
        error: null,
      };
    case GET_STORE_PERFORMANCE_SUCCESS:
      return {
        ...state,
        storePerformanceList: action.payload.data,
        loading: false,
        error: null,
      };
    case GET_POINTS_EARNED_SUCCESS: 
      return {
        ...state,
        pointsEarned : action.payload.data,
        loading: false,
        error: null,
      }
    case GET_POINT_SUCCESS:
      return {
        ...state,
        pointList: action.payload.data,
        loading: false,
        error: null,
      };
    case GET_ACCOUNT_SUCCESS:
      return {
        ...state,
        accountList: action.payload.data,
        loading: false,
        error: null,
      };
    case ACTION_SUCCESS:
      return {
        ...state,
        message: action.payload.message,
        loading: false,
        error: null,
      };
    case ADMIN_LOGIN:
      return {
        adminToken: action.payload.token,
        loading: false,
        error: null,
      };

    case ACTION_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};
