import {
  headerConfig,
  retailerTokenConfig,
  returnError,
  tokenConfig,
  dsrTokenConfig,
} from "./helperFunc";

import { GLOBAL_CLEAR_ERROR } from "./userDetailsReducer";
import axios from "axios";

export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";

const SET_LOADING = "SET_LOADING";
const CLEAR_ERROR = "CLEAR_ERROR";
const FAIL_DATA = "FAIL_DATA";
const CHECK_SESSION_SUCCESS = "  CHECK_SESSION_SUCCESS";
const SUCCESS_DATA = "SUCCESS_DATA";
const CHECK_NEW_QR = "CHECK_NEW_QR";
const UPDATE_STORE_DETAILS = "UPDATE_STORE_DETAILS";
const UPDATE_PAYMENT_DETAILS = "UPDATE_PAYMENT_DETAILS";
const urlPath = "/api/retailer";

export const retailerSession = (_params) => async (dispatch) => {
  try {
    dispatch({ type: CLEAR_ERROR });
    dispatch({ type: SET_LOADING });

    const { data } = await axios.post(
      `${urlPath}/retailer/session`,
      _params,
      retailerTokenConfig()
    );
    dispatch({ type: CHECK_SESSION_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: FAIL_DATA, payload: returnError(error) });
  }
};

export const dsrSession = (currentPath) => async (dispatch) => {
  try {
    dispatch({ type: CLEAR_ERROR });
    dispatch({ type: SET_LOADING });

    const { data } = await axios.post(
      `${urlPath}/dsr/session`,
      {},
      dsrTokenConfig()
    );
    dispatch({ type: CHECK_SESSION_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: FAIL_DATA, payload: returnError(error) });
  }
};

export const checkNewQr = (currentPath) => async (dispatch) => {
  try {
    dispatch({ type: CLEAR_ERROR });
    dispatch({ type: SET_LOADING });

    const { data } = await axios.post(
      `${urlPath}/checkNewQr`,
      {},
      retailerTokenConfig()
    );
    dispatch({ type: CHECK_NEW_QR, payload: data });
  } catch (error) {
    dispatch({ type: FAIL_DATA, payload: returnError(error) });
  }
};

export const updateStoreDetails = (currentPath) => async (dispatch) => {
  try {
    dispatch({ type: CLEAR_ERROR });
    dispatch({ type: SET_LOADING });

    // console.log("currentpath", currentPath);

    const { data } = await axios.post(
      `${urlPath}/store/details`,
      currentPath,
      dsrTokenConfig()
    );

    dispatch({ type: UPDATE_STORE_DETAILS, payload: data });
  } catch (error) {
    dispatch({ type: FAIL_DATA, payload: returnError(error) });
  }
};

export const createPaymentDetails = (currentPath) => async (dispatch) => {
  try {
    dispatch({ type: CLEAR_ERROR });
    dispatch({ type: SET_LOADING });

    const { data } = await axios.post(
      `${urlPath}/payment/info/create`,
      currentPath,
      retailerTokenConfig()
    );
    dispatch({ type: UPDATE_PAYMENT_DETAILS, payload: data });
  } catch (error) {
    dispatch({ type: FAIL_DATA, payload: returnError(error) });
  }
};

export const logoutRetailer = () => (dispatch) => {
  dispatch({ type: CLEAR_ERROR });
  dispatch({ type: SET_LOADING });
  localStorage.removeItem("retailer-token");
  dispatch({ type: LOGOUT_SUCCESS });
};

const initialState = {
  retailer: null,
  error: null,
  retailerToken: localStorage.getItem("retailer-token") || null,
  dashboardData: [],
};

export const retailerReducer = function (state = initialState, action) {
  switch (action.type) {
    // this is for fetching loading time setter
    case LOGOUT_SUCCESS:
      return initialState;

    case SUCCESS_DATA:
      return {
        ...state,
        message: action.payload.message,
      };
    case CHECK_SESSION_SUCCESS:
      return {
        ...state,
        dashboardData: action.payload.dashboardData,
        retailerClaimHistory: action.payload.retailerClaimHistory,
        getQuarterlyEntitlement: action.payload.getQuarterlyEntitlement,
        userInfo: action.payload.userInfo,
        statementID: action.payload.statementID,
      };
    case CHECK_NEW_QR:
      return {
        ...state,
      };
    case UPDATE_STORE_DETAILS:
      return {
        ...state,
        message: action.payload.message,
      };
    case UPDATE_PAYMENT_DETAILS:
      return {
        ...state,
        message: action.payload.message,
        error: null,
      };
    case FAIL_DATA:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};
