import { useState, useEffect } from "react";
import { Button } from 'antd';
import { dsrSession, logoutRetailer } from "../../reducer/retailerReducer";
import { useDispatch, useSelector } from "react-redux";
import headerLogo from "../../images/SalesRep/HeaderSalesRep.png";
import "./SalesRep.css";
import { useNavigate } from "react-router-dom";
import { useForm } from "antd/es/form/Form";
import logoutBtn from "../../images/logout.png";

const SalesRepInstruction = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const localtoken = localStorage.getItem("retailer-token");
    const { dashboardData } = useSelector((state) => state.retailer);

    useEffect(() => {
        dispatch(dsrSession({ token: localtoken }));
    }, []);

    const logOut = () => {
        dispatch(logoutRetailer());
        navigate("/operator/login" + window.location.search);
      };
    
 
    return (
    <div>
        <div className="ret-login-home">
            <div>
                <img src={headerLogo} alt="" className="w-100"/>
                <img
        src={logoutBtn}
        alt="logout_btn"
        className="logout-button"
        onClick={logOut}
      />
            </div>

            <div className="ml-3 mr-3 mt-2">
                <div>
                    <h1 style={{ fontSize: '25px', fontWeight: '700' }}>Instruction:</h1>
                </div>

                <div className="text-center">
                    <div>
                    <ol
                    style={{
                        padding: "0 26px",
                        fontSize: "16px",
                        textAlign: "justify",
                    }}
                    >
                    <li className="my-1">
                        Scan QR code that has been displayed in the POSM.
                    </li>
                    <li className="my-1">
                        Fill the store details.
                    </li>
                    <li className="my-1">
                        Upload any receipt from the store.
                    </li>
                    <li className="my-1">
                        Successfully register the store
                    </li>
                    </ol>
                    </div>

                    <div style={{ paddingTop: '120px' }}>
                        <Button
                        type="primary"
                        className="mt-1"
                        onClick={() => navigate("/salesRep/scanQR")}
                        style={{
                            width: '291px',
                            height: '45px',
                            borderRadius: '2px',
                            backgroundColor: '#000',
                            color: 'white',
                            fontSize: '16px',
                            // fontFamily: 'Gotham',
                            fontWeight: '700',
                        }}
                        >
                        Scan QR Code Now
                        </Button> 

                        <Button
                        type="primary"
                        className="mt-2"
                        onClick={() => navigate("/salesRep/home")}
                        style={{
                            color: '#000',
                            fontSize: '16px',
                            fontWeight: '700',
                            width: '291px',
                            height: '45px',
                            border: '1px solid #000',
                            backgroundColor: 'white',
                            marginBottom: '80px',
                        }}
                        >
                        Back
                        </Button>  
                    </div>
                </div>
            </div>
        </div>
    </div>
    );
};

export default SalesRepInstruction;
