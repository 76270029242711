import { useState, useEffect } from 'react';
import { Button, Form, Input, Select } from 'antd';
import { dsrSession, logoutRetailer } from '../../reducer/retailerReducer';
import { useDispatch, useSelector } from 'react-redux';
import headerLogo from '../../images/SalesRep/HeaderSalesRep.png';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { updateStoreDetails } from '../../reducer/retailerReducer';
import './SalesRep.css';
import logoutBtn from '../../images/logout.png';

const SalesRepInstruction = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const location = useLocation();
	const localtoken = localStorage.getItem('retailer-token');
	const { dashboardData } = useSelector((state) => state.retailer);
	const [filter, setFilter] = useState();
	const [form] = Form.useForm();
	const { error } = useSelector((state) => state.userDetails);
	const [selectedState, setSelectedState] = useState('');
	const summaryQR = location.state?.inputValue;
	const [errorNumber, setErrorNumber] = useState(' ');
	const [number, setNumber] = useState('');

	useEffect(() => {
		dispatch(dsrSession({ token: localtoken }));
	}, []);

	const stateArr = [
		'Perlis',
		'Kedah',
		'Penang',
		'Perak',
		'Selangor',
		'Negeri Sembilan',
		'Melaka',
		'Johor',
		'Kelantan',
		'Terengganu',
		'Pahang',
		'Sabah',
		'Sarawak',
	];

	// useEffect(() => {
	//   if(dashboardData) {
	//   form.setFieldsValue ({
	//     name: dashboardData?.name,
	//     number: dashboardData?.number,
	//     code: dashboardData?.code,
	//     state: dashboardData?.state
	//   })
	// }
	// }, [dashboardData])

	const isNumber = (e) => {
		// eslint-disable-next-line no-useless-escape
		let regNumber = /^(01)[0-46-9]([0-9]){7,8}$/;
		if (!regNumber.test(e.target.value)) {
			setErrorNumber('Invalid number format');
		} else {
			setErrorNumber('');
			setNumber(e.target.value);
		}
		if (!e.target.value) {
			setErrorNumber('');
		}
	};

	const logOut = () => {
		dispatch(logoutRetailer());
		navigate('/operator/login'+window.location.search);
	};

	const onFinish = (values) => {
		navigate('/salesRep/upload', { state: { summaryQR, values } });
	};

	return (
		<div>
			<div className="ret-login-home">
				<div>
					<img src={headerLogo} alt="" className="w-100" />
					<img
						src={logoutBtn}
						alt="logout_btn"
						className="logout-button"
						onClick={logOut}
					/>
				</div>

				<div className="ml-3 mr-3 mt-2">
					<div>
						<h1
							style={{ fontSize: '24px', fontWeight: '700' }}
							className="text-center"
						>
							Store Details
						</h1>
					</div>

					<div className="text-center">
						<div className="mt-2">
							<p className="text-center">
								Please enter the store details to register <br />
								for Haleon Partner Program. This is to <br />
								activate QR code for the shopper’s user <br />
								availability.
							</p>
						</div>

						<div className="mt-3" style={{ paddingBottom: '20px' }}>
							<Form
								layout="vertical"
								name="add_update_address"
								form={form}
								onFinish={onFinish}
								autoComplete="off"
							>
								<div>
									<div>
										<Form.Item
											name="name"
											label={
												<label className="form-item-label">
													{t('Store Name: ')}
												</label>
											}
											rules={[
												{
													message: t('inputName'),
												},
											]}
										>
											<Input
												type="text"
												placeholder={t('Enter store name...')}
												required
											/>
										</Form.Item>

										<Form.Item
											name="number"
											label={
												<label className="form-item-label">
													{t('Store Phone Number: ')}
												</label>
											}
										>
											<Input
												type="text"
												name="number"
												onChange={isNumber}
												placeholder="Enter store phone number..."
												required={true}
											/>
										</Form.Item>
										{errorNumber ? (
											<>
												<div
													style={{
														color: 'red',
														marginTop: '-5%',
														textAlign: 'center',
													}}
												>
													{errorNumber}
												</div>
												<br />
											</>
										) : null}

										<Form.Item
											name="code"
											label={
												<label className="form-item-label">
													{t('Store Code:')}
												</label>
											}
											rules={[
												{
													message: t('inputStoreCode'),
												},
											]}
											style={{ color: 'red' }}
										>
											<Input
												type="text"
												placeholder={t('Enter store code...')}
												required
											/>
										</Form.Item>

										<Form.Item
											name="state"
											label={
												<label className="form-item-label">
													{t('Store State:')}
												</label>
											}
											rules={[
												{
													message: t('inputState'),
												},
											]}
											style={{ minWidth: '150px' }}
											className="w-100 ms-2"
										>
											<Select
												value={selectedState}
												onChange={(value) => setSelectedState(value)}
												placeholder="Select store state..."
												className="store-details-dropdown"
												style={{
													textAlign: 'left',
												}}
												required
											>
												{stateArr.map((state) => (
													<Select.Option key={state} value={state}>
														{state}
													</Select.Option>
												))}
											</Select>
										</Form.Item>
									</div>

                  <Form.Item>
                    <div style={{
                      maxWidth: '291px',
                    }}>
                    <Button
											htmlType="submit"
											type="primary"
											style={{
                        width: '100%',
												height: '45px',
												borderRadius: '2px',
												backgroundColor: '#000',
												color: 'white',
												fontSize: '16px',
												fontWeight: '700',
												marginTop: '30px',
											}}
										>
											Next
										</Button>
                    </div>
										
									</Form.Item>
								</div>
							</Form>
						</div>
						<br />
					</div>
				</div>
			</div>
		</div>
	);
};

export default SalesRepInstruction;
