import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './App.css';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Provider } from 'react-redux';
import { configureStore } from '@reduxjs/toolkit';
import { userReducer } from './reducer/userReducer';
import { BrowserRouter } from 'react-router-dom';
import { adminReducer } from './reducer/adminReducer';
import { userDetailsReducer } from './reducer/userDetailsReducer';
import { retailerReducer } from './reducer/retailerReducer';
import dsrReducer from './reducer/dsr/dsrReducer';

const store = configureStore({
	reducer: {
		user: userReducer,
		admin: adminReducer,
		userDetails: userDetailsReducer,
		retailer: retailerReducer,
		dsr: dsrReducer,
	},
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
	<BrowserRouter>
		<Provider store={store}>
			<App />
		</Provider>
	</BrowserRouter>
);

serviceWorkerRegistration.unregister();
reportWebVitals();
