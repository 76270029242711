import React, { useRef } from 'react';
import whatsapp from '../images/whatsappIcon.png';
import Draggable from 'react-draggable';
import useWindowDimensions from '../hooks/useWindowDimensions';

const DraggableWhatsapp = () => {
	const dragStartPositionXYRef = useRef();
	const { height, width } = useWindowDimensions();

	return (
		<div
			style={{
				// overlay container on top
				top: 20,
				zIndex: 1000,
				position: 'fixed',
				// prevent dragging to the edge
				width: 'calc(100% - 40px)',
				maxWidth: 'calc(430px - 40px)',
				height: 'calc(100% - 40px)',
				maxHeight: 'calc(100vh - 40px)',
				// ensure the behind elements are clickable
				pointerEvents: 'none',
				// always the overlay container div
				left: '50%',
				transform: 'translate(-50%, 0)',
			}}
		>
			<Draggable
				// ensure default is always within the parent div
				defaultPosition={{
					x: 0,y:height - 250
				}}
				// style={{
				// 	position: 'absolute',
				// 	zIndex: 1000,
				// 	pointerEvents: 'auto',
				// }}
				bounds="parent"
				onStart={(_, data) => {
					// Record the dataing position of the drag, so we can detect later if
					// the user actually dragged the popup or just clicked on it
					dragStartPositionXYRef.current = { x: data.x, y: data.y };
				}}
				onStop={(_, data) => {
					// Only treat the drag as a real one if the popup moved at least a
					// threshold number of pixels in any direction
					const THRESHOLD = 2;
					const { x, y } = dragStartPositionXYRef.current ?? { x: 0, y: 0 };
					const wasDragged =
						Math.abs(data.x - x) > THRESHOLD &&
						Math.abs(data.y - y) > THRESHOLD;
					if (!wasDragged) {
						window.open(`https://wa.me/${process.env.REACT_APP_MY_SALE_REP_WHATSAPP}`, '_blank');
					}
				}}
			>
				<div
					style={{
						width: '79.861px',
						height: '73px',
						position: 'fixed',
						pointerEvents: 'auto', // Ensure pointer events are enabled for Draggable
					}}
				>
					<img
						src={whatsapp}
						alt="whatsapp_img"
						style={{
							width: '100%',
							pointerEvents: 'none', // Ensure pointer events are enabled for Draggable
						}}
					/>
				</div>
			</Draggable>
		</div>
	);
};

export default DraggableWhatsapp;
